import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfoLink } from "../../../types";

type UIState = {
  isLeftNavBarFixed: boolean;
  activeTab: string | null;
  todayHours: number;
  weeklyHours: number;
  scheduledHours: number;
  photo: string | null | undefined;
  links: UserInfoLink[];
  modulePermissions: object;
  userRolesTimesModule: string[];
  userRolesProductsModule: string[];
  isLoadingUserInfo: boolean;
};

const initialState: UIState = {
  isLeftNavBarFixed: localStorage.getItem("isLeftNavBarFixed")
    ? localStorage.getItem("isLeftNavBarFixed") === "true"
      ? true
      : false
    : false,
  activeTab: null,
  todayHours: 0,
  weeklyHours: 0,
  scheduledHours: 8,
  photo: undefined,
  links: [],
  modulePermissions: {},
  userRolesTimesModule: [],
  userRolesProductsModule: [],
  isLoadingUserInfo: true,
};

export const uiSharedSlice = createSlice({
  name: "uiShared",
  initialState,
  reducers: {
    // Remember Redux Toolkit allows us to write "mutating" logic in reducers.
    toggleLeftNavBarFixed: (state) => {
      state.isLeftNavBarFixed = !state.isLeftNavBarFixed;
      localStorage.setItem(
        "isLeftNavBarFixed",
        state.isLeftNavBarFixed.toString()
      );
    },
    forceCollapseLeftNavBarFixed: (state) => {
      state.isLeftNavBarFixed = false;
      localStorage.setItem("isLeftNavBarFixed", "false");
    },
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
    setHours: (
      state,
      action: PayloadAction<{
        totalHoursDay: number;
        totalHoursWeek: number;
        scheduledHours: number;
      }>
    ) => {
      state.todayHours = action.payload.totalHoursDay;
      state.weeklyHours = action.payload.totalHoursWeek;
      state.scheduledHours = action.payload.scheduledHours;
    },
    setInfoUser: (
      state,
      action: PayloadAction<{
        photo: string;
        links: UserInfoLink[];
        modulePermissions: object;
      }>
    ) => {
      state.photo = action.payload.photo;
      state.modulePermissions = action.payload.modulePermissions;
      let links = action.payload.links;
      if (links) {
        state.links = links.sort((a: UserInfoLink, b: UserInfoLink) =>
          a.title.localeCompare(b.title)
        );
      }
    },
    setRolesByModule: (state, action: PayloadAction<any>) => {
      state.userRolesTimesModule = action.payload.timesRoles;
      state.userRolesProductsModule = action.payload.productsRoles;
      state.isLoadingUserInfo = false;
    },
    clear: (state) => {
      state.todayHours = 0;
      state.weeklyHours = 0;
      state.scheduledHours = 8;
      state.photo = undefined;
      state.links = [];
      state.modulePermissions = {};
      state.userRolesTimesModule = [];
      state.userRolesProductsModule = [];
      state.isLoadingUserInfo = true;
    },
  },
});

export const {
  toggleLeftNavBarFixed,
  forceCollapseLeftNavBarFixed,
  setActiveTab,
  setHours,
  setInfoUser,
  setRolesByModule,
  clear,
} = uiSharedSlice.actions;

// Selectors
export const actualUIState = ({ uiShared }: { uiShared: UIState }) => ({
  isLeftNavBarFixed: uiShared.isLeftNavBarFixed,
  activeTab: uiShared.activeTab,
  todayHours: uiShared.todayHours,
  weeklyHours: uiShared.weeklyHours,
  scheduledHours: uiShared.scheduledHours,
  photo: uiShared.photo,
  links: uiShared.links,
  modulePermissions: uiShared.modulePermissions,
  userRolesTimesModule: uiShared.userRolesTimesModule,
  userRolesProductsModule: uiShared.userRolesProductsModule,
  isLoadingUserInfo: uiShared.isLoadingUserInfo,
});

export default uiSharedSlice.reducer;
