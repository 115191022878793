import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Spinner } from "../../SharedModule/components/Spinner";
import { ROUTE_PRODUCT_KEYCONTACTS_DETAILS } from "../../SharedModule/utils/constants";
import { KeyContactsService } from "../services/keyContacts";
import { ConfidenceHistory } from "../types/types";
import { PaginatedItems } from "./../components/PaginatedItems";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { GetActualProductName } from "../redux/reducers/spotlight.reducer";
import { useSelector } from "react-redux";

export const ConfidenceHistoryPage = () => {
  const { trackPageView } = useMatomo();

  let { productId, contactId } = useParams();
  const actualProductName = useSelector(GetActualProductName);

  let initialState: ConfidenceHistory[] = [];

  const [confidenceHistory, setConfidenceHistory] = useState(initialState);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [hasConfidenceHistory, setHasConfidenceHistory] = useState(false);

  const loadConfidenceHistory = async () => {
    let response = await KeyContactsService.getConfidendeHistory(
      Number(productId),
      Number(contactId)
    );
    if (response && response.length > 0) {
      setConfidenceHistory(response);
      setHasConfidenceHistory(true);
    }
    setIsLoadingData(false);
  };

  useEffect(() => {
    document.title = "The Hub - Confidence History";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    loadConfidenceHistory();
  }, []);

  const getUrlBack = (productId: any, contactId: any) => {
    return ROUTE_PRODUCT_KEYCONTACTS_DETAILS.replace(
      ":productId",
      productId
    ).replace(":contactId", contactId);
  };

  return (
    <>
      {isLoadingData && (
        <Spinner
          style={{
            marginLeft: "40%",
            marginTop: "5%",
          }}
        />
      )}
      {!isLoadingData && (
        <div className="content">
          <Link
            className="back-button-container text-decoration-none"
            to={getUrlBack(productId, contactId)}
          >
            <BiLeftArrowAlt className="back-button-arrow" />
            <span className="back-button-text">Back to Contact Details</span>
          </Link>
          <h2>{actualProductName} - Stackholder Confidence History</h2>
          {hasConfidenceHistory && (
            <PaginatedItems
              itemsPerPage={10}
              items={confidenceHistory}
              type={"stackholder"}
            />
          )}
        </div>
      )}
    </>
  );
};
