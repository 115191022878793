import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "../../SharedModule/redux/reducers/auth.reducer";
import { getHostUrl } from "../utils/reportsUtils";
import { TelerikReport } from "../../SharedModule/components/TelerikReport";
import {
  actualUIState,
  setActiveTab,
} from "../../SharedModule/redux/reducers/uiShared.reducer";
import { useEffect } from "react";
import {
  PRODUCTS_REPORTS,
  ROUTE_PRODUCT_ADVOCACY_DETAIL_REPORT,
} from "../../SharedModule/utils/constants";
import { verifyModuleAccess } from "../../SharedModule/utils/accessUtils";
import { Spinner } from "../../SharedModule/components/Spinner";
import { ForbiddenPage } from "../../SharedModule/pages/ForbiddenPage";

export const AdvocacyDetailReportPage = () => {
  const auth = useSelector(selectAuth);
  let parameters = {
    HostUrl: getHostUrl(),
    Auth: auth.accessToken,
  };
  const dispatch = useDispatch();

  // times module admin
  const { userRolesProductsModule, isLoadingUserInfo } =
    useSelector(actualUIState);

  useEffect(() => {
    document.title = "The Hub - Advocacy Detail Report";
    dispatch(setActiveTab(ROUTE_PRODUCT_ADVOCACY_DETAIL_REPORT));
  }, []);

  return (
    <>
      {isLoadingUserInfo && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {!isLoadingUserInfo &&
        !verifyModuleAccess(
          true,
          PRODUCTS_REPORTS,
          userRolesProductsModule
        ) && <ForbiddenPage />}
      {!isLoadingUserInfo &&
        verifyModuleAccess(true, PRODUCTS_REPORTS, userRolesProductsModule) && (
          <TelerikReport
            title="Advocacy Detail Report"
            reportName="AdvocacyDetailSummary.trdp"
            parameters={parameters}
            parametersAreaVisible={false}
          />
        )}
    </>
  );
};
