import {
  PRODUCTS_ACCESS_CONTROL_REPORTS,
  PRODUCTS_REPORTS,
  ROLE_PRODUCT_VIEWER,
  ROUTE_ACCESS_CONTROL_REPORT_BY_GITLAB_PROJECT,
  ROUTE_ACCESS_CONTROL_REPORT_BY_JIRA_PROJECT,
  ROUTE_ACCESS_CONTROL_REPORT_BY_USER,
  ROUTE_PRODUCTS_LIST,
  ROUTE_PRODUCT_ADVOCACY_DETAIL_REPORT,
  ROUTE_PRODUCT_ADVOCACY_SUMMARY_REPORT,
  ROUTE_PRODUCT_RELATIONSHIP_REVIEW_REPORT,
  ROUTE_PRODUCT_RELATIONSHIP_UPDATES_REPORT,
  ROUTE_PRODUCT_SPOTLIGHT_SUMMARY_REPORT,
} from "../../SharedModule/utils/constants";
import { LeftNavBar } from "../../SharedModule/components/LeftNavBar";
import { LeftNavBarSection } from "../../SharedModule/components/LeftNavBarSection";
import { useSelector } from "react-redux";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";

export const LeftNavBarProducts = (props: any) => {
  const { userRolesProductsModule } = useSelector(actualUIState);

  return (
    <LeftNavBar>
      <LeftNavBarSection
        mainTitle="Products"
        biIconName="BiCube"
        moduleAccess={true}
        role={[ROLE_PRODUCT_VIEWER]}
        moduleRoles={userRolesProductsModule}
        listOfLinks={[
          {
            text: "Product List",
            route: ROUTE_PRODUCTS_LIST,
            moduleAccess: true,
            role: [ROLE_PRODUCT_VIEWER],
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Reports"
        biIconName="BiBarChartAlt2"
        moduleAccess={true}
        role={PRODUCTS_REPORTS}
        moduleRoles={userRolesProductsModule}
        listOfLinks={[
          {
            text: "Spotlight Summary",
            route: ROUTE_PRODUCT_SPOTLIGHT_SUMMARY_REPORT,
            moduleAccess: true,
            role: PRODUCTS_REPORTS,
          },
          {
            text: "Advocacy Summary",
            route: ROUTE_PRODUCT_ADVOCACY_SUMMARY_REPORT,
            moduleAccess: true,
            role: PRODUCTS_REPORTS,
          },
          {
            text: "Advocacy Detail",
            route: ROUTE_PRODUCT_ADVOCACY_DETAIL_REPORT,
            moduleAccess: true,
            role: PRODUCTS_REPORTS,
          },
          {
            text: "Relationship Updates",
            route: ROUTE_PRODUCT_RELATIONSHIP_UPDATES_REPORT,
            moduleAccess: true,
            role: PRODUCTS_REPORTS,
          },
          {
            text: "Relationship Review",
            route: ROUTE_PRODUCT_RELATIONSHIP_REVIEW_REPORT,
            moduleAccess: true,
            role: PRODUCTS_REPORTS,
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Access Control Report"
        biIconName="BiBarChartAlt2"
        moduleAccess={true}
        role={PRODUCTS_ACCESS_CONTROL_REPORTS}
        moduleRoles={userRolesProductsModule}
        listOfLinks={[
          {
            text: "By User",
            route: ROUTE_ACCESS_CONTROL_REPORT_BY_USER,
            moduleAccess: true,
            role: PRODUCTS_ACCESS_CONTROL_REPORTS,
          },
          {
            text: "By Jira Project",
            route: ROUTE_ACCESS_CONTROL_REPORT_BY_JIRA_PROJECT,
            moduleAccess: true,
            role: PRODUCTS_ACCESS_CONTROL_REPORTS,
          },
          {
            text: "By Gitlab Project",
            route: ROUTE_ACCESS_CONTROL_REPORT_BY_GITLAB_PROJECT,
            moduleAccess: true,
            role: PRODUCTS_ACCESS_CONTROL_REPORTS,
          },
        ]}
      />
    </LeftNavBar>
  );
};
