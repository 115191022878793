import HttpService from "../../SharedModule/utils/api";
const BASE_URL = "/products/Users";


function getUserRolesForProductsModule(): Promise<any> {
    return HttpService.req(`${BASE_URL}/roles`);
  }
  
  export const UsersProductsService = {
    getUserRolesForProductsModule
  };