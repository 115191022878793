import { createSlice } from "@reduxjs/toolkit";
import { generateWeekHeaders } from "../../utils/mealBreakHoursUtils";
import { PayPeriod, PayPeriods } from "../../types";

type MealBreakReportState = {
  isLoading: boolean;
  currentPayPeriod: any;
  mealBreakUserList: any;
  weekHeaders: any[] | null;
  payPeriods: PayPeriods | null;
  yearPayPeriods: PayPeriod[] | null;
  isInfoPayPeriodsLoaded: boolean;
};

const initialState: MealBreakReportState = {
  isLoading: true,
  currentPayPeriod: null,
  mealBreakUserList: null,
  weekHeaders: null,
  payPeriods: null,
  yearPayPeriods: null,
  isInfoPayPeriodsLoaded: false,
};

export const mealBreakReportSlice = createSlice({
  name: "mealBreakReport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setInfoPayPeriods: (state, action) => {
      state.payPeriods = action.payload.payPeriods;
      state.yearPayPeriods = action.payload.yearPayPeriods;
      state.weekHeaders = generateWeekHeaders(
        action.payload.payPeriods.current.startDate
      );
      state.isInfoPayPeriodsLoaded = true;
    },
    setCurrentPayPeriod: (state, action) => {
      state.currentPayPeriod = action.payload;
      state.weekHeaders = generateWeekHeaders(state.currentPayPeriod.startDate);
    },
    mealBreakReportInfo: (state, action) => {
      state.mealBreakUserList = action.payload.data;
      state.isLoading = false;
    },
    setWeekHeaders: (state, action) => {
      state.weekHeaders = action.payload;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setInfoPayPeriods,
  setCurrentPayPeriod,
  mealBreakReportInfo,
  setWeekHeaders,
  reset,
} = mealBreakReportSlice.actions;

export const IsLoading = (state: any) => {
  return state.mealBreakReport.isLoading;
};

export const SelectCurrentPayPeriod = (state: any) => {
  return state.mealBreakReport.currentPayPeriod;
};

export const GetMealBreakUserList = (state: any) => {
  return state.mealBreakReport.mealBreakUserList;
};

export const GetWeekHeaders = (state: any) => {
  return state.mealBreakReport.weekHeaders;
};

export const selectPayPeriods = (state: any) => {
  return state.mealBreakReport.payPeriods;
};

export const selectYearPayPeriods = (state: any) => {
  return state.mealBreakReport.yearPayPeriods;
};

export const selectIsInfoPayPeriodsLoaded = (state: any) => {
  return state.mealBreakReport.isInfoPayPeriodsLoaded;
};

export default mealBreakReportSlice.reducer;
