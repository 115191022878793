import { useRef, useState } from "react";
import useOnClickOutside from "../../SharedModule/hooks/useOnClickOutside";
import {
  APPROVED_TEXT,
  billableIds,
  LOCKED,
  nonBillableIds,
  PENDING_TEXT,
} from "../../SharedModule/utils/constants";
import { isClosed, isProjectClosed } from "../utils/timesUtils";

export const ActionsButton = ({
  disabled,
  entriesSelected,
  showActionNonBillaleModal,
  showActionChangeDateModal,
  showActionChangeProjectModal,
  updateEntriesSelected,
  activeUserId,
}: any) => {
  const [actionsOpen, setActionsOpen] = useState(false);
  const dropdownRef: any = useRef();

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(dropdownRef, () => {
    setActionsOpen(false);
  });

  const disabledBillable = () => {
    return (
      entriesSelected.some((e) =>
        billableIds.includes(e.timeEntryTypeId.toString().toLowerCase())
      ) ||
      entriesSelected.some((e) => e.status === APPROVED_TEXT) ||
      entriesSelected.some((e) => e.status === LOCKED) ||
      entriesSelected.some((e) => isClosed(e.entityStatus)) || 
      entriesSelected.some((e) => isProjectClosed(e))
    );
  };

  const disabledNonBillable = () => {
    return (
      entriesSelected.some((e) =>
        nonBillableIds.includes(e.timeEntryTypeId.toString().toLowerCase())
      ) ||
      entriesSelected.some((e) => e.status === APPROVED_TEXT) ||
      entriesSelected.some((e) => e.status === LOCKED) ||
      entriesSelected.some((e) => isClosed(e.entityStatus)) || 
      entriesSelected.some((e) => isProjectClosed(e))
    );
  };

  const disabledApproveTime = () => {
    return (
      entriesSelected.some((e) => e.status === LOCKED) ||
      entriesSelected.some((e) => e.categoryId === 3) ||
      entriesSelected.some((e) => !e.canApprove) ||
      entriesSelected.some((e) => e.userId === activeUserId) ||
      entriesSelected.some((e) => isClosed(e.entityStatus)) || 
      entriesSelected.some((e) => isProjectClosed(e))
    );
  };

  const disabledUnapproveTime = () => {
    return (
      entriesSelected.some((e) => e.status === PENDING_TEXT) ||
      entriesSelected.some((e) => e.status === LOCKED) ||
      entriesSelected.some((e) => e.categoryId === 3) ||
      entriesSelected.some((e) => !e.canApprove) ||
      entriesSelected.some((e) => isClosed(e.entityStatus)) || 
      entriesSelected.some((e) => isProjectClosed(e))
    );
  };

  const disabledMove = () => {
    return (
      entriesSelected.some((e) => e.status === APPROVED_TEXT) ||
      entriesSelected.some((e) => e.status === LOCKED) ||
      entriesSelected.some((e) => isClosed(e.entityStatus)) || 
      entriesSelected.some((e) => isProjectClosed(e))
    );
  };

  return (
    <span className="position-relative">
      <button
        className={`btn button-secondary dropdown-toggle ${
          disabled && "disabled"
        }`}
        type="button"
        onClick={() => setActionsOpen((state) => !state)}
      >
        Actions
      </button>
      <div
        className={`dropdown-menu ${actionsOpen ? "show" : ""}`}
        ref={dropdownRef}
      >
        <button
          className="dropdown-item"
          onClick={() => {
            updateEntriesSelected(1, null);
            setActionsOpen(false);
          }}
          disabled={disabledBillable()}
        >
          Make Billable
        </button>
        <button
          className="dropdown-item"
          onClick={() => {
            showActionNonBillaleModal();
            setActionsOpen(false);
          }}
          disabled={disabledNonBillable()}
        >
          Make Non-Billable
        </button>
        <hr className="separator-actions" />
        <button
          className="dropdown-item"
          onClick={() => {
            updateEntriesSelected(3, null);
            setActionsOpen(false);
          }}
          disabled={disabledApproveTime()}
        >
          Approve Time
        </button>
        <button
          className="dropdown-item"
          onClick={() => {
            updateEntriesSelected(4, null);
            setActionsOpen(false);
          }}
          disabled={disabledUnapproveTime()}
        >
          Unapprove Time
        </button>
        <hr className="separator-actions" />
        <button
          className="dropdown-item"
          onClick={() => {
            showActionChangeDateModal();
            setActionsOpen(false);
          }}
          disabled={disabledMove()}
        >
          Move Date
        </button>
        <button
          className="dropdown-item"
          onClick={() => {
            showActionChangeProjectModal();
            setActionsOpen(false);
          }}
          disabled={disabledMove()}
        >
          Move to Project
        </button>
      </div>
    </span>
  );
};
