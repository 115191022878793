import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIsAllowed } from "../../SharedModule/components/AccessControl";
import {
  load,
  setIsLoadingInvoices,
  selectFilters,
  IsLoadingInvoices,
  selectOrderedBy,
  setFilters,
} from "../redux/reducers/invoices.reducer";
import { BillingService } from "../services/billing";
import {
  BILLING_MODULE,
  ROLE_INVOICE_APPROVER_OWNER,
  ROLE_SUPER_ADMIN,
} from "../../SharedModule/utils/constants";
import { selectUserRoles } from "../../SharedModule/redux/reducers/auth.reducer";
import { isOnlyRecurringBillingAdminRole } from "../../SharedModule/utils/accessUtils";

export const useInvoices = (forceReload?: boolean) => {
  const dispatch = useDispatch();
  const filtersState = useSelector(selectFilters);
  const isLoadingInvoices = useSelector(IsLoadingInvoices);
  const { list: invoices, allSelected } = useSelector(selectOrderedBy);
  const [initialized, setInitialized] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  // ASUITE11-2133: this rule is when user only have "Invoice Approver - Owner", this set owner filter to this user
  const {
    activeUserId,
    activeUserName,
    isAllowed: isOwner,
  } = useIsAllowed(ROLE_INVOICE_APPROVER_OWNER);

  const { userRoles } = useSelector(selectUserRoles);

  // ASUITE11-2467
  const asuiteAdminRecurringBillingRoute: any =
    process.env.REACT_APP_ASUITE_ADMIN_BILLING_RECURRING;

  const getFiltersData = () => {
    let filters = { ...filtersState };
    let data: any = {};

    const { dateFrom, dateTo, idsInputValue, ...rest } = filtersState;

    if (!idsInputValue) {
      data = { dateFrom, dateTo };
    }

    Object.keys(rest).forEach((key: string) => {
      if (Array.isArray(filters[key])) {
        data[key] = filters[key].map((filter: any) => filter.value || filter);
      } else if (filters[key]) {
        data[key] = filters[key];
      }
    });

    return data;
  };

  const loadInvoices = useCallback(async () => {
    dispatch(setIsLoadingInvoices(true));
    let data = getFiltersData();
    const invoices = await BillingService.searchInvoices(data);
    dispatch(load(invoices));
    setIsLoadingPage(false);
  }, [filtersState, dispatch]);

  useEffect(() => {
    if (initialized) return;
    if (isOnlyRecurringBillingAdminRole(userRoles)) {
      window.location.href = asuiteAdminRecurringBillingRoute;
    }
    // check if multiple billing roles and add to condition
    let multipleBillingRole: any = [];
    // check if has admin roles
    let hasAdminRoles: boolean = false;
    userRoles?.forEach((role) => {
      if (role.module.name.toLowerCase() === BILLING_MODULE) {
        multipleBillingRole.push(role);
      }
      if (role.name === ROLE_SUPER_ADMIN) {
        hasAdminRoles = true;
      }
    });
    if (
      forceReload &&
      isOwner &&
      multipleBillingRole.length <= 1 &&
      !hasAdminRoles
    ) {
      // Fresh start
      dispatch(
        setFilters({
          invoiceOwners: [{ value: activeUserId, label: activeUserName }],
        })
      );
    }
    setInitialized(true);
  }, [
    initialized,
    isOwner,
    forceReload,
    activeUserId,
    activeUserName,
    dispatch,
  ]);

  useEffect(() => {
    if (!initialized) return;
    if (isOnlyRecurringBillingAdminRole(userRoles)) {
      window.location.href = asuiteAdminRecurringBillingRoute;
    } else {
      loadInvoices();
    }
  }, [initialized, loadInvoices]);

  return {
    invoices,
    allSelected,
    loadInvoices,
    isLoadingInvoices,
    getFiltersData,
    isLoadingPage
  };
};
