import image from "./../../styles/legacy/gandalf.png";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import { NoContent } from "../components/NoContent";
import Footer from "../components/Footer";
import { forceCollapseLeftNavBarFixed } from "../redux/reducers/uiShared.reducer";
import { useDispatch } from "react-redux";

export const ForbiddenPage = () => {
  const { trackPageView } = useMatomo();
  const dispatch = useDispatch();

  dispatch(forceCollapseLeftNavBarFixed());

  useEffect(() => {
    document.title = "The Hub - Forbidden";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
  }, []);

  return (
    <div className="content">
      <NoContent
        title="You don´t have permission to access this page"
        text={
          <>
            Please contact{" "}
            <a className="link-black" href="mailto:asuitesupport@itx.com">
              asuitesupport@itx.com
            </a>{" "}
            if you think you need it.
          </>
        }
        image={image}
        style={{ margin: "3% auto" }}
      />
      <Footer />
    </div>
  );
};
