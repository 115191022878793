import { createSlice } from "@reduxjs/toolkit";

type WhoIsOutReportState = {
  isLoading: boolean;
  isInfoWhoIsOutLoaded: boolean;
  costCenterWhoIsOut: Array<any> | null;
  projectsWhoIsOut: Array<any> | null;
  filters: {
    groupByDate: boolean;
    costCenter: Array<any> | null;
    project: Array<any> | null;
    timeFrame: any;
  };
  whoIsOutUserList: any[] | null;
};

const initialState: WhoIsOutReportState = {
  isLoading: true,
  isInfoWhoIsOutLoaded: false,
  costCenterWhoIsOut: null,
  projectsWhoIsOut: null,
  filters: {
    groupByDate: true,
    costCenter: null,
    project: null,
    timeFrame: { value: 1, label: "Next 30 days" },
  },
  whoIsOutUserList: [],
};

export const whoIsOutReportSlice = createSlice({
  name: "whoIsOutReport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setInfoWhoIsOut: (state, action) => {
      state.costCenterWhoIsOut = action.payload.costCenterWhoIsOut;
      state.projectsWhoIsOut = action.payload.projectsWhoIsOut;
      state.isInfoWhoIsOutLoaded = true;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    loadWhoIsOutInfo: (state, action) => {
      state.whoIsOutUserList = action.payload;
      state.isLoading = false;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setInfoWhoIsOut, setFilters, loadWhoIsOutInfo, resetFilters } =
  whoIsOutReportSlice.actions;

export const IsLoading = (state: any) => {
  return state.whoIsOutReport.isLoading;
};

export const SelectFilters = (state: any) => {
  return state.whoIsOutReport.filters;
};

export const SelectIsInfoWhoIsOutLoaded = (state: any) => {
  return state.whoIsOutReport.isInfoWhoIsOutLoaded;
};

export const SelectCostCenterWhoIsOut = (state: any) => {
  return state.whoIsOutReport.costCenterWhoIsOut;
};

export const SelectProjectsWhoIsOut = (state: any) => {
  return state.whoIsOutReport.projectsWhoIsOut;
};

export const GetWhoIsOutUserList = (state: any) => {
  return state.whoIsOutReport.whoIsOutUserList;
};

export default whoIsOutReportSlice.reducer;
