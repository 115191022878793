import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Footer from "../../SharedModule/components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  ROUTE_TIMESHEETS_REPORTS,
  TIME_VIEWER_AND_ADMIN_ACCESS,
} from "../../SharedModule/utils/constants";
import { Link } from "react-router-dom";
import { Spinner } from "../../SharedModule/components/Spinner";
import { addElementToArray } from "../utils/timesUtils";
import {
  actualUIState,
  setActiveTab,
} from "../../SharedModule/redux/reducers/uiShared.reducer";
import { useWhoIsOutReport } from "../hooks/useWhoIsOutReport";
import { verifyModuleAccess } from "../../SharedModule/utils/accessUtils";
import { BiLeftArrowAlt, BiInfoCircle } from "react-icons/bi";
import { ForbiddenPage } from "../../SharedModule/pages/ForbiddenPage";
import { Select } from "../../SharedModule/components/Select";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { Radio } from "../../SharedModule/components/Radio";
import { WhoIsOutUser } from "../types";
import {
  resetFilters,
  SelectCostCenterWhoIsOut,
  SelectFilters,
  SelectIsInfoWhoIsOutLoaded,
  SelectProjectsWhoIsOut,
  setFilters,
  setInfoWhoIsOut,
} from "../redux/reducers/whoIsOutReport.reducer";
import {
  nameDayCompleteDateFormat,
  quantityFormat,
} from "../../SharedModule/utils/formatters";
import { newUuid } from "../../SharedModule/utils/uuid";
import { getDateFromDateString } from "../../SharedModule/utils/dateUtils";
import {
  compare,
  groupDataByDate,
  groupDataByPerson,
  timeFrameOptios,
} from "../utils/sortUtils";
import { NoContent } from "../../SharedModule/components/NoContent";
import image from "./../../styles/legacy/404.png";
import { TimesheetsService } from "../services/timesheets";

export const WhoIsOutReportsPage = () => {
  const { trackPageView } = useMatomo();
  const dispatch = useDispatch();

  const filters = useSelector(SelectFilters);
  const isInfoWhoIsOutLoaded = useSelector(SelectIsInfoWhoIsOutLoaded);
  const costCenterWhoIsOut = useSelector(SelectCostCenterWhoIsOut);
  const projectsWhoIsOut = useSelector(SelectProjectsWhoIsOut);

  // times module admin
  const { userRolesTimesModule, isLoadingUserInfo } =
    useSelector(actualUIState);

  // Local info to set filters at first load
  const [localInfoWhoIsOut, setLocalInfoWhoIsOut] = useState({
    costCenterWhoIsOut: costCenterWhoIsOut,
    projectsWhoIsOut: projectsWhoIsOut,
  });

  const { isLoading, whoIsOutUserList } = useWhoIsOutReport(
    filters,
    isInfoWhoIsOutLoaded,
    userRolesTimesModule,
    isLoadingUserInfo
  );

  // call endpoint to have page filters
  const fetchInfoWhoIsOut = async () => {
    let whoIsOutFilters,
      costCenterWhoIsOutData = [];
    await TimesheetsService.getWhoisOutFilters().then((response) => {
      whoIsOutFilters = response.data;
    });
    if (whoIsOutFilters) {
      addElementToArray(costCenterWhoIsOutData, whoIsOutFilters.costCenters);
    }
    return {
      costCenterWhoIsOut: costCenterWhoIsOutData || null,
      projectsWhoIsOut:
        whoIsOutFilters?.projects?.map((elem: any) => ({
          value: elem.entityId,
          label: elem.entityId + " - " + elem.entityName,
          categoryId: elem.categoryId,
        })) || null,
    };
  };

  // ASUITE11-2479: To sort columns gruped by
  const [sortField, setSortField] = useState(
    filters.groupByDate ? "person" : "date"
  );
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState({
    orderBy: filters.groupByDate ? "person" : "date",
    criteria: "asc",
  });

  const groupedData = filters.groupByDate
    ? groupDataByDate(whoIsOutUserList)
    : groupDataByPerson(whoIsOutUserList);

  const handleSort = (field, sortOrderByParam) => {
    const order = sortOrderByParam
      ? sortOrderByParam
      : sortOrder === "asc"
      ? "desc"
      : "asc";
    setSortBy({
      orderBy: field,
      criteria: order,
    });
    setSortField(field);
    setSortOrder(order);
  };

  const sortedData = Object.entries(groupedData).map(
    ([groupedValue, records]: [string, any]) => {
      const sortedRecords = [...records].sort((a, b) => {
        if (sortField === "date") {
          return sortOrder === "asc"
            ? compare(
                getDateFromDateString(a.date) > getDateFromDateString(b.date)
              )
            : compare(
                getDateFromDateString(b.date) > getDateFromDateString(a.date)
              );
        } else if (sortField === "hours") {
          return sortOrder === "asc" ? a.hours - b.hours : b.hours - a.hours;
        } else if (sortField === "person") {
          return sortOrder === "asc"
            ? compare(a.person.toLowerCase() > b.person.toLowerCase())
            : compare(b.person.toLowerCase() > a.person.toLowerCase());
        } else if (sortField === "description") {
          return sortOrder === "asc"
            ? compare(a.description > b.description)
            : compare(b.description > a.description);
        } else if (sortField === "costCenter") {
          return sortOrder === "asc"
            ? compare(a.costCenter > b.costCenter)
            : compare(b.costCenter > a.costCenter);
        }
        return 0;
      });
      return { groupedValue, records: sortedRecords };
    }
  );

  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);

  const handleChange = (value: any, field: string) => {
    setSubmitEnabled(true);
    setReportFilters({
      ...reportFilters,
      [field]: value,
    });
  };

  const setLocalFilters = (structureData) => {
    setReportFilters({
      timeFrame: filters.timeFrame,
      costCenter: filters.costCenter,
      project:
        structureData.projectsWhoIsOut?.length === 1
          ? [structureData.projectsWhoIsOut[0]]
          : filters.project,
      groupByDate: filters.groupByDate,
    });
  };

  const [reportFilters, setReportFilters] = useState({
    timeFrame: filters.timeFrame,
    costCenter: filters.costCenter,
    project: filters.project,
    groupByDate: filters.groupByDate,
  });

  useEffect(() => {
    document.title = "The Hub - Who Is Out In The Future Report";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    const loadInfoWhoIsOut = async () => {
      let metaInfoWhoIsOut: any = await fetchInfoWhoIsOut();
      setLocalInfoWhoIsOut(metaInfoWhoIsOut);
      dispatch(setInfoWhoIsOut(metaInfoWhoIsOut));
      setLocalFilters(metaInfoWhoIsOut);
    };

    if (!isInfoWhoIsOutLoaded) {
      loadInfoWhoIsOut();
    } else {
      setLocalFilters(localInfoWhoIsOut);
    }

    dispatch(setActiveTab(ROUTE_TIMESHEETS_REPORTS));

    return () => {
      dispatch(resetFilters());
    };
  }, [trackPageView]);

  const setPageFilters = () => {
    if (reportFilters.groupByDate) {
      handleSort("person", "asc");
    } else {
      handleSort("date", "asc");
    }
    dispatch(setFilters(reportFilters));
  };

  return (
    <>
      {(!isInfoWhoIsOutLoaded || isLoadingUserInfo) && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {isInfoWhoIsOutLoaded &&
        !isLoadingUserInfo &&
        !verifyModuleAccess(
          true,
          TIME_VIEWER_AND_ADMIN_ACCESS,
          userRolesTimesModule
        ) && <ForbiddenPage />}
      {isInfoWhoIsOutLoaded &&
        !isLoadingUserInfo &&
        verifyModuleAccess(
          true,
          TIME_VIEWER_AND_ADMIN_ACCESS,
          userRolesTimesModule
        ) && (
          <div className="content">
            <div className="mt-2 button-header">
              <Link
                className="back-button-container text-decoration-none"
                to={ROUTE_TIMESHEETS_REPORTS}
              >
                <BiLeftArrowAlt className="back-button-arrow" />
                <span className="back-button-text">Back to Time reports</span>
              </Link>
            </div>
            <div className="d-flex flex-wrap">
              <h2 className="flex-fill">Who is out in the future</h2>
            </div>

            <form className="card entry-hour-box-background p-4 mt-3 mb-5">
              <div className="d-flex mb-4 justify-content-between">
                <div className="flex-column col-sm-2">
                  <label className="col-form-label">Time Frame</label>
                  <Select
                    placeholder="All"
                    options={timeFrameOptios}
                    value={reportFilters.timeFrame}
                    onChange={(timeFrame) =>
                      handleChange(timeFrame, "timeFrame")
                    }
                    isDisabled={isLoading}
                  />
                </div>
                <div className="flex-column col-sm-2">
                  <label className="col-form-label">
                    Cost Center
                    <Tooltip
                      title={<b>List users that belong to this Cost Center</b>}
                      placement="right"
                      TransitionComponent={Zoom}
                      arrow
                    >
                      <span>
                        <BiInfoCircle className="disc-icon ms-1" />
                      </span>
                    </Tooltip>
                  </label>
                  <Select
                    isMulti
                    placeholder="All Cost Centers"
                    options={costCenterWhoIsOut}
                    value={reportFilters.costCenter}
                    onChange={(costCenter) =>
                      handleChange(costCenter, "costCenter")
                    }
                    isDisabled={isLoading}
                  />
                </div>
                <div className="flex-column col-sm-2">
                  <label className="col-form-label">Projects</label>
                  <Select
                    isMulti
                    placeholder="All"
                    options={projectsWhoIsOut}
                    value={reportFilters.project}
                    onChange={(project) => handleChange(project, "project")}
                    isDisabled={isLoading}
                  />
                </div>
                <div className="flex-column col-sm-2">
                  <label className="col-form-label">Group By</label>
                  <div>
                    <div className="w-50 d-inline-block">
                      <Radio
                        label="Date"
                        value={reportFilters.groupByDate}
                        checked={reportFilters.groupByDate}
                        onChange={() => handleChange(true, "groupByDate")}
                      />
                    </div>
                    <div className="w-50 d-inline-block">
                      <Radio
                        label="User"
                        value={!reportFilters.groupByDate}
                        checked={!reportFilters.groupByDate}
                        onChange={() => handleChange(false, "groupByDate")}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ marginTop: "35px" }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setPageFilters();
                    }}
                    disabled={!submitEnabled}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>

            <hr className="header-separator" />

            {isLoading && (
              <Spinner
                style={{
                  marginLeft: "45%",
                  marginTop: "10%",
                }}
              />
            )}

            {!isLoading && (!sortedData || sortedData.length === 0) && (
              <div className="py-5">
                <NoContent
                  title="No Results"
                  image={image}
                  style={{ margin: "0 auto" }}
                />
              </div>
            )}

            {!isLoading &&
              sortedData
                .sort((a: any, b: any) => {
                  return a.groupedValue.toLowerCase() <
                    b.groupedValue.toLowerCase()
                    ? -1
                    : 1;
                })
                .map(({ groupedValue, records }) => {
                  return (
                    <>
                      {filters.groupByDate && (
                        <span className="title-table mt-3">
                          Out on {nameDayCompleteDateFormat(groupedValue)}
                        </span>
                      )}
                      {!filters.groupByDate && (
                        <span className="title-table mt-3">
                          Out days for {groupedValue}
                        </span>
                      )}
                      <table
                        className="grey-table-header-background time-confirmation table mb-4"
                        style={{ width: "100%" }}
                      >
                        <thead className="align-middle sticky-header sticky">
                          <tr>
                            <th
                              className={`border-left border-right border-top fw-500 py-3 ps-4 column-20 ${
                                sortBy.orderBy === "person"
                                  ? "sorted-" + sortBy.criteria
                                  : ""
                              }`}
                              key="person"
                              id="Person"
                              onClick={() => handleSort("person", null)}
                            >
                              Person
                            </th>
                            <th
                              className={`border-top border-right fw-500 py-3 ps-4 column-20 ${
                                sortBy.orderBy === "costCenter"
                                  ? "sorted-" + sortBy.criteria
                                  : ""
                              }`}
                              key="costCenter"
                              id="CostCenter"
                              onClick={() => handleSort("costCenter", null)}
                            >
                              Cost Center
                            </th>
                            <th
                              className={`border-top border-right fw-500 py-3 ps-4 column-20 ${
                                sortBy.orderBy === "date"
                                  ? "sorted-" + sortBy.criteria
                                  : ""
                              }`}
                              key="date"
                              id="Date"
                              onClick={() => handleSort("date", null)}
                            >
                              Date
                            </th>
                            <th
                              className={`border-top border-right fw-500 py-3 ps-4 column-20 ${
                                sortBy.orderBy === "hours"
                                  ? "sorted-" + sortBy.criteria
                                  : ""
                              }`}
                              key="hours"
                              id="Hours"
                              onClick={() => handleSort("hours", null)}
                            >
                              Hours
                            </th>
                            <th
                              className={`border-top border-right fw-500 py-3 ps-4 column-20 ${
                                sortBy.orderBy === "description"
                                  ? "sorted-" + sortBy.criteria
                                  : ""
                              }`}
                              key="description"
                              id="Description"
                              onClick={() => handleSort("description", null)}
                            >
                              Description
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {records.map((record: WhoIsOutUser, index) => (
                            <tr
                              key={newUuid()}
                              style={{ verticalAlign: "middle" }}
                              className={
                                index % 2 === 0 ? "even-row" : "odd-row"
                              }
                            >
                              <td className="py-3 ps-4 fw-500">
                                <div className="td-text">{record.person}</div>
                              </td>
                              <td className="py-3 ps-4 fw-500">
                                <div className="td-text">
                                  {record.costCenter}
                                </div>
                              </td>
                              <td className="py-3 ps-4 fw-500">
                                <div className="td-text">
                                  {record.dateString}
                                </div>
                              </td>
                              <td className="py-3 ps-4 fw-500">
                                <div className="td-text">
                                  {quantityFormat(record.hours)}
                                </div>
                              </td>
                              <td className="py-3 ps-4 fw-500">
                                {record.description}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  );
                })}

            <Footer />
          </div>
        )}
    </>
  );
};
