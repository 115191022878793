import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import {
  actualUIState,
  setHours,
  setInfoUser,
  setRolesByModule,
} from "../../SharedModule/redux/reducers/uiShared.reducer";
import { UsersService } from "../services/users";
import { selectUserRoles } from "../redux/reducers/auth.reducer";
import { TimesheetsService } from "../../TimesModule/services/timesheets";
import { UsersProductsService } from "../../ProductsModule/services/users";

export const useTopNavigationInfo = () => {
  const dispatch = useDispatch();
  const { activeUserEmail } = useSelector(selectUserRoles);
  const {
    todayHours,
    weeklyHours,
    photo,
    links,
    modulePermissions,
    userRolesTimesModule,
    userRolesProductsModule,
  } = useSelector(actualUIState);

  const loadInfo = useCallback(async () => {
    if (activeUserEmail) {
      const [
        infoHours,
        userInfo,
        userRolesTimesModule,
        userRolesProductsModule,
      ] = await Promise.all([
        TimesheetsService.getWeeklyHours(),
        UsersService.getUserProfileInfo(activeUserEmail),
        TimesheetsService.getUserRolesForTimeModule(),
        UsersProductsService.getUserRolesForProductsModule(),
      ]);

      if (infoHours.data) {
        dispatch(setHours(infoHours.data));
      }
      if (userInfo) {
        dispatch(
          setInfoUser({
            photo: userInfo.photo,
            links: userInfo.links,
            modulePermissions: userInfo.modulePermissions,
          })
        );
      }
      if (
        userRolesTimesModule &&
        userRolesTimesModule.data &&
        userRolesProductsModule &&
        userRolesProductsModule.data
      ) {
        dispatch(
          setRolesByModule({
            timesRoles: userRolesTimesModule.data,
            productsRoles: userRolesProductsModule.data,
          })
        );
      }
    }
  }, [dispatch, activeUserEmail]);

  useEffect(() => {
    loadInfo();
  }, [loadInfo]);

  return {
    todayHours,
    weeklyHours,
    photo,
    links,
    modulePermissions,
    userRolesTimesModule,
    userRolesProductsModule,
    loadInfo,
  };
};
