import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Footer from "../../SharedModule/components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  ROUTE_TIMESHEETS_REPORTS,
  SLASH,
  TIME_SUPER_ADMIN_ACCESS,
} from "../../SharedModule/utils/constants";
import { Link } from "react-router-dom";
import { Spinner } from "../../SharedModule/components/Spinner";
import {
  actualUIState,
  setActiveTab,
} from "../../SharedModule/redux/reducers/uiShared.reducer";
import { verifyModuleAccess } from "../../SharedModule/utils/accessUtils";
import { BiLeftArrowAlt, BiError } from "react-icons/bi";
import { ForbiddenPage } from "../../SharedModule/pages/ForbiddenPage";
import { Select } from "../../SharedModule/components/Select";
import { useMealBreakReport } from "../hooks/useMealBreakReport";
import {
  checkDayWeekend,
  generateWeekHeaders,
  missingMealBreakTime,
} from "../utils/mealBreakHoursUtils";
import {
  reset,
  SelectCurrentPayPeriod,
  selectIsInfoPayPeriodsLoaded,
  selectPayPeriods,
  selectYearPayPeriods,
  setCurrentPayPeriod,
  setInfoPayPeriods,
  setWeekHeaders,
} from "../redux/reducers/mealBreakReport.reducer";
import { MealBreakDay, PayPeriod } from "../types";
import { NoContent } from "../../SharedModule/components/NoContent";
import image from "./../../styles/legacy/404.png";
import { TimesheetsService } from "../services/timesheets";

export const MealBreakReportsPage = () => {
  const { trackPageView } = useMatomo();
  const dispatch = useDispatch();

  const currentPayPeriod = useSelector(SelectCurrentPayPeriod);
  const payPeriods = useSelector(selectPayPeriods);
  const yearPayPeriods = useSelector(selectYearPayPeriods);
  const isInfoPayPeriodsLoaded = useSelector(selectIsInfoPayPeriodsLoaded);

  // times module admin
  const { isLoadingUserInfo } = useSelector(actualUIState);

  // call endpoint to have page filters
  const fetchYearPayPeriods = async () => {
    let payPeriods, yearPayPeriods;

    await TimesheetsService.getPayPeriods().then((response) => {
      payPeriods = response.data;
    });
    await TimesheetsService.getYearPayPeriods().then((response) => {
      yearPayPeriods = response.data;
    });

    return {
      payPeriods: payPeriods.data,
      yearPayPeriods:
        yearPayPeriods?.data.map((period: PayPeriod) => ({
          value:
            period.startDate.split("T")[0].replaceAll("-", "/") +
            " - " +
            period.endDate.split("T")[0].replaceAll("-", "/"),
          label:
            period.startDate.split("T")[0].replaceAll("-", "/") +
            " - " +
            period.endDate.split("T")[0].replaceAll("-", "/"),
          startDate: period.startDate,
          endDate: period.endDate,
        })) || [],
    };
  };

  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);

  const {
    isLoading,
    mealBreakUserList,
    userRolesTimesModule,
    weekHeaders,
  } = useMealBreakReport(
    currentPayPeriod,
    isLoadingUserInfo,
    isInfoPayPeriodsLoaded
  );

  // Local info to set currentPayPeriod at first load
  const [localCurrentPayPeriod, setLocalCurrentPayPeriod] = useState({
    payPeriods: payPeriods,
    yearPayPeriods: yearPayPeriods,
  });

  const setLocalSelectedPayPeriod = (localCurrentPayPeriod) => {
    setSelectedPayPeriod({
      value:
        localCurrentPayPeriod.current.startDate
          .split("T")[0]
          .replaceAll("-", "/") +
        " - " +
        localCurrentPayPeriod.current.endDate
          .split("T")[0]
          .replaceAll("-", "/"),
      label:
        localCurrentPayPeriod.current.startDate
          .split("T")[0]
          .replaceAll("-", "/") +
        " - " +
        localCurrentPayPeriod.current.endDate
          .split("T")[0]
          .replaceAll("-", "/"),
      startDate: localCurrentPayPeriod.current.startDate,
      endDate: localCurrentPayPeriod.current.endDate,
    });
  };

  const [selectedPayPeriod, setSelectedPayPeriod] = useState(currentPayPeriod);

  useEffect(() => {
    document.title = "The Hub - Meal Break Report";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    const loadInfoPayPeriods = async () => {
      let metaInfoPayPeriods: any = await fetchYearPayPeriods();
      setLocalCurrentPayPeriod(metaInfoPayPeriods.payPeriods);
      dispatch(setInfoPayPeriods(metaInfoPayPeriods));
      setLocalSelectedPayPeriod(metaInfoPayPeriods.payPeriods);
    };

    if (!isInfoPayPeriodsLoaded) {
      loadInfoPayPeriods();
    } else {
      setLocalSelectedPayPeriod(localCurrentPayPeriod.payPeriods);
    }

    dispatch(setActiveTab(ROUTE_TIMESHEETS_REPORTS));
    return () => {
      dispatch(reset());
    };
  }, [trackPageView]);

  const submit = (event: any) => {
    event.preventDefault();
    dispatch(setWeekHeaders(generateWeekHeaders(selectedPayPeriod?.startDate)));
    dispatch(setCurrentPayPeriod(selectedPayPeriod));
  };

  return (
    <>
      {(!isInfoPayPeriodsLoaded || isLoadingUserInfo) && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {isInfoPayPeriodsLoaded &&
        !isLoadingUserInfo &&
        !verifyModuleAccess(true, TIME_SUPER_ADMIN_ACCESS, userRolesTimesModule) && (
          <ForbiddenPage />
        )}
      {isInfoPayPeriodsLoaded &&
        !isLoadingUserInfo &&
        verifyModuleAccess(true, TIME_SUPER_ADMIN_ACCESS, userRolesTimesModule) && (
          <div className="content">
            <div className="mt-2 button-header">
              <Link
                className="back-button-container text-decoration-none"
                to={ROUTE_TIMESHEETS_REPORTS}
              >
                <BiLeftArrowAlt className="back-button-arrow" />
                <span className="back-button-text">Back to Time reports</span>
              </Link>
            </div>
            <div className="d-flex flex-wrap">
              <h2 className="flex-fill">Meal Break report</h2>
            </div>

            <form className="card entry-hour-box-background p-4 mt-3 mb-5">
              <div className="d-flex mb-4">
                <div className="flex-column col-sm-2">
                  <label className="col-form-label">Pay Period</label>
                  <Select
                    placeholder="All"
                    options={yearPayPeriods}
                    value={selectedPayPeriod}
                    onChange={(payPeriod) => {
                      setSubmitEnabled(true);
                      setSelectedPayPeriod(payPeriod);
                    }}
                    isDisabled={
                      isLoading || isLoadingUserInfo || !isInfoPayPeriodsLoaded
                    }
                  />
                </div>
                <div
                  className="d-flex flex-column ms-3"
                  style={{ marginTop: "35px" }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={(e) => submit(e)}
                    disabled={isLoading || !submitEnabled}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>

            <hr className="header-separator" />

            {isLoading && (
              <Spinner
                style={{
                  marginLeft: "50%",
                  marginTop: "5%",
                }}
              />
            )}

            {!isLoading && Object.keys(mealBreakUserList).length === 0 && (
              <div className="py-5">
                <NoContent
                  title="No Results"
                  image={image}
                  style={{ margin: "0 auto" }}
                />
              </div>
            )}

            {!isLoading && Object.keys(mealBreakUserList).length > 0 && (
              <table
                className="grey-table-header-background time-confirmation table my-5"
                style={{ width: "100%" }}
              >
                <thead className="align-middle sticky-header sticky">
                  <tr>
                    <th
                      className={`border-left border-right border-top fw-500 py-3 ps-4 column-16 sorted-desc`}
                      key="person"
                      id="Person"
                    >
                      Person
                    </th>
                    {weekHeaders}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(mealBreakUserList).map((elem, index) => (
                    <tr>
                      <td
                        className={`${missingMealBreakTime(
                          mealBreakUserList,
                          elem
                        )} border-right fw-500`}
                      >
                        <div className="d-flex border-meal-break">
                          <div className="td-text">
                            {Object.keys(mealBreakUserList)[index]}
                          </div>
                        </div>
                      </td>
                      {mealBreakUserList[elem].map(
                        (day: MealBreakDay, index) => (
                          <td
                            className={`border-left border-right py-3 px-3 fw-500 ${
                              checkDayWeekend(index) ? "table-totals" : ""
                            }`}
                          >
                            {!day.missingTime && (
                              <div className="td-text text-center">
                                {day.mealBreakRequired ? day.hours : SLASH}
                              </div>
                            )}
                            {day.missingTime && (
                              <div className="td-text text-center">
                                <BiError className="error-icon big" />
                              </div>
                            )}
                          </td>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            <Footer />
          </div>
        )}
    </>
  );
};
