import { useDispatch, useSelector } from "react-redux";
import { getHostUrl } from "../../ProductsModule/utils/reportsUtils";
import { TelerikReport } from "../components/TelerikReport";
import { useEffect } from "react";
import {
  actualUIState,
  setActiveTab,
} from "../redux/reducers/uiShared.reducer";
import {
  PRODUCTS_ACCESS_CONTROL_REPORTS,
  ROUTE_ACCESS_CONTROL_REPORT_BY_GITLAB_PROJECT,
} from "../utils/constants";
import { Spinner } from "../components/Spinner";
import { verifyModuleAccess } from "../utils/accessUtils";
import { ForbiddenPage } from "./ForbiddenPage";

export const AccessControlReportPageByGitlabProject = () => {
  let parameters = {
    HostUrl: getHostUrl(),
  };

  const dispatch = useDispatch();

  // times module admin
  const { userRolesProductsModule, isLoadingUserInfo } =
    useSelector(actualUIState);

  useEffect(() => {
    document.title = "The Hub - Access Control Report By Gitlab Project";
    dispatch(setActiveTab(ROUTE_ACCESS_CONTROL_REPORT_BY_GITLAB_PROJECT));
  }, []);

  return (
    <>
      {isLoadingUserInfo && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {!isLoadingUserInfo &&
        !verifyModuleAccess(
          true,
          PRODUCTS_ACCESS_CONTROL_REPORTS,
          userRolesProductsModule
        ) && <ForbiddenPage />}
      {!isLoadingUserInfo &&
        verifyModuleAccess(
          true,
          PRODUCTS_ACCESS_CONTROL_REPORTS,
          userRolesProductsModule
        ) && (
          <TelerikReport
            title="Access Control Report By Gitlab Project"
            reportName="AccessControlReportByGitlabProject.trdp"
            parameters={parameters}
            parametersAreaVisible={true}
          />
        )}
    </>
  );
};
