import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetActualProductName,
  IsLoadingData,
  GetKeyContactList,
  loadKeyContactListData,
  setActualProductName,
  setIsContentEditable,
  GetIsContentEditable,
  selectOrderedBy,
  GetIsProductActive,
  setIsProductActive,
} from "../redux/reducers/keyContact.reducer";
import { KeyContactsService } from "./../services/keyContacts";
import {
  ROUTE_FORBIDDEN_PAGE,
  ROUTE_PRODUCTS_LIST,
  STATUS_ERROR_FORBIDDEN,
} from "../../SharedModule/utils/constants";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { useNavigate } from "react-router-dom";

export const useKeyContacts = (productId: number) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actualProductName = useSelector(GetActualProductName);
  const isContentEditable = useSelector(GetIsContentEditable);
  const isProductActive = useSelector(GetIsProductActive);
  const isLoadingData = useSelector(IsLoadingData);
  const keyContactList = useSelector(GetKeyContactList);

  // could be load with an endpoint
  const trendingOptions = [
    { value: 1, label: "Up" },
    { value: 2, label: "Down" },
    { value: 3, label: "Same" },
  ];

  const { keyContactList: keyContacts } = useSelector(selectOrderedBy);

  const loadKeyContactsInfo = useCallback(async () => {
    const keyContactsData = await KeyContactsService.getKeyContacts(productId)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        dispatch(
          addToast({
            mode: "error",
            message:
              error.response.data.errors[1] ?? error.response.data.errors[0],
          })
        );
        if (error.response.status === STATUS_ERROR_FORBIDDEN) {
          navigate(ROUTE_FORBIDDEN_PAGE);
        } else {
          navigate(ROUTE_PRODUCTS_LIST);
        }
        return error.response.data;
      });

    let action: any = {
      keyContactList: keyContactsData.errors
        ? keyContactsData.data
        : keyContactsData.data.keyContactList,
    };
    dispatch(loadKeyContactListData(action));

    dispatch(setActualProductName(keyContactsData.data.productName));

    dispatch(setIsContentEditable(keyContactsData.data.isContentEditable));

    dispatch(setIsProductActive(keyContactsData.data.isProductActive));
  }, [dispatch, productId]);

  useEffect(() => {
    loadKeyContactsInfo();
  }, [loadKeyContactsInfo]);

  return {
    keyContacts,
    actualProductName,
    isContentEditable,
    isProductActive,
    isLoadingData,
    keyContactList,
    trendingOptions,
  };
};
