import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EntryList,
  Holidays,
  IsListView,
  IsLoading,
  ProjectList,
  Summary,
  TimeEntriesList,
  TimeEntriesSheetView,
  loadTimesInfo,
  SelectProjectOrWorkOrder,
  setLoading,
  ScheduledHours,
  MissingTime,
  MissingTimeMealBreak,
} from "../redux/reducers/times.reducer";
import { TimesheetsService } from '../services/timesheets';
import { quantityFormat } from "../../SharedModule/utils/formatters";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";
import {
  hasSuperTimeAdminAccess,
  hasTimeAdminAccess,
  isSupervisorOrProjectOwner,
  hasTimeViewerAccess,
} from "../utils/timesUtils";
import {
  selectMetaTimesData,
  selectUserAssignees,
} from "../redux/reducers/metaTimes.reducer";
import {
  getIsoFormatStringWithTimeZone,
  getNextWeekDay,
  getPrevSunday,
} from "../../SharedModule/utils/dateUtils";

export const useTimes = (
  actualUserId: any,
  pageDate: Date,
  userEmail: string
) => {
  const dispatch = useDispatch();
  const isListView = useSelector(IsListView);
  const isLoading = useSelector(IsLoading);
  const projectList = useSelector(ProjectList);
  const selectProjectOrWorkOrder = useSelector(SelectProjectOrWorkOrder);
  const timeEntriesList = useSelector(TimeEntriesList);
  const timeEntriesSheetView = useSelector(TimeEntriesSheetView);
  const summary = useSelector(Summary);
  const scheduledHours = useSelector(ScheduledHours);
  const holidays = useSelector(Holidays);
  const entryList = useSelector(EntryList);
  const MetaTimesInfo = useSelector(selectMetaTimesData);
  const missingTime = useSelector(MissingTime);
  const missingTimeMealBreak = useSelector(MissingTimeMealBreak);

  // times module admin
  const { userRolesTimesModule } = useSelector(actualUIState);
  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);
  const isTimeViewer = hasTimeViewerAccess(userRolesTimesModule);
  const isSuperTimeAdmin = hasSuperTimeAdminAccess(userRolesTimesModule);
  const isSupervisorOrPO = isSupervisorOrProjectOwner(userRolesTimesModule);

  // ASUITE11-2418: if user is Time Viewer and supervisor/project owner, we should use timeUsers (from query) intead of usersAssignees
  const users = MetaTimesInfo.timeUsers?.filter((user) => user.isActive);
  const usersAssignees = useSelector(selectUserAssignees);
  let usersForDropdown = isTimeViewer ? users : usersAssignees;

  const loadTimesPageInfo = useCallback(
    async (pageDate: Date) => {
      dispatch(setLoading(true));
      let [projectsWorkOrders, timeEntries, missingTime, missingTimeMealBreak] = await Promise.all([
        TimesheetsService.getUserProjects(actualUserId),
        TimesheetsService.getTimesEntries(
          actualUserId,
          getIsoFormatStringWithTimeZone(getPrevSunday(pageDate)),
          getIsoFormatStringWithTimeZone(getNextWeekDay(6, pageDate))
        ),
        TimesheetsService.getMissingTime(userEmail),
        TimesheetsService.getMealBreakReminderInfo(userEmail)
      ]);

      const partial = projectsWorkOrders.data.map((elem) => ({
        value: elem.entityId,
        label: elem.showBudget
          ? elem.name +
            " (" +
            quantityFormat(elem.budgetRemaining) +
            " budget hours remaining)"
          : elem.name,
        categoryId: elem.categoryId,
        entityId: elem.entityId,
        isInternal: elem.isInternal,
        defaultTaskTypeId: elem.defaultTaskTypeId,
        taskTypesCategory: elem.taskTypesCategory,
      }));
      const info = {
        projectList: partial,
        timeEntriesInfo: timeEntries,
        pageDate: pageDate,
        missingTime: missingTime,
        missingTimeMealBreak: missingTimeMealBreak
      };
      dispatch(loadTimesInfo(info));
    },
    [dispatch, actualUserId]
  );

  useEffect(() => {
    loadTimesPageInfo(pageDate);
  }, [loadTimesPageInfo]);

  return {
    isLoading,
    isListView,
    projectList,
    selectProjectOrWorkOrder,
    timeEntriesList,
    timeEntriesSheetView,
    summary,
    scheduledHours,
    holidays,
    entryList,
    isTimeAdmin,
    isTimeViewer,
    isSuperTimeAdmin,
    isSupervisorOrPO,
    MetaTimesInfo,
    loadTimesPageInfo,
    missingTime,
    missingTimeMealBreak,
    usersForDropdown,
  };
};
