import { useDispatch, useSelector } from "react-redux";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";
import {
  GetMealBreakUserList,
  mealBreakReportInfo,
  IsLoading,
  setLoading,
  GetWeekHeaders,
  selectPayPeriods,
} from "../redux/reducers/mealBreakReport.reducer";
import { useCallback, useEffect } from "react";
import { TimesheetsService } from "../services/timesheets";

export const useMealBreakReport = (
  currentPayPeriod: any,
  isLoadingUserInfo: boolean,
  isInfoPayPeriodsLoaded: boolean
) => {
  const dispatch = useDispatch();

  const { userRolesTimesModule } = useSelector(actualUIState);
  // times module admin
  const mealBreakUserList = useSelector(GetMealBreakUserList);
  const weekHeaders = useSelector(GetWeekHeaders);
  const isLoading = useSelector(IsLoading);

  const payPeriods = useSelector(selectPayPeriods);

  let currentPayPeriodForRequest;
  if (isInfoPayPeriodsLoaded && !isLoadingUserInfo) {
    if (currentPayPeriod) {
      currentPayPeriodForRequest = currentPayPeriod;
    } else {
      currentPayPeriodForRequest = {
        value:
          payPeriods.current.startDate.split("T")[0].replaceAll("-", "/") +
          " - " +
          payPeriods.current.endDate.split("T")[0].replaceAll("-", "/"),
        label:
          payPeriods.current.startDate.split("T")[0].replaceAll("-", "/") +
          " - " +
          payPeriods.current.endDate.split("T")[0].replaceAll("-", "/"),
        startDate: payPeriods.current.startDate,
        endDate: payPeriods.current.endDate,
      };
    }
  }

  const mealBreakReportResults = useCallback(async () => {
    dispatch(setLoading(true));
    if (isInfoPayPeriodsLoaded && !isLoadingUserInfo) {
      const results = await TimesheetsService.getMealBreakReportInfo(
        currentPayPeriodForRequest.startDate,
        currentPayPeriodForRequest.endDate
      );
      dispatch(mealBreakReportInfo(results));
    }
  }, [dispatch, currentPayPeriod, isLoadingUserInfo, isInfoPayPeriodsLoaded]);

  useEffect(() => {
    mealBreakReportResults();
  }, [mealBreakReportResults]);

  return {
    isLoading,
    mealBreakUserList,
    userRolesTimesModule,
    isLoadingUserInfo,
    weekHeaders,
  };
};
