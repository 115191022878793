import { useDispatch, useSelector } from "react-redux";
import {
  IsLoading,
  loadTimeEntriesInfo,
  SelectManagersApprovalTime,
  selectOrderedBy,
  SelectProjectsApprovalTime,
  setLoading,
} from "../redux/reducers/approvalTime.reducer";
import { useCallback, useEffect } from "react";
import { TimesheetsService } from "../services/timesheets";
import {
  hasSuperTimeAdminAccess,
  isSupervisorOrProjectOwner,
  preparedFiltersToApprove,
} from "../utils/timesUtils";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { selectUserRoles } from "../../SharedModule/redux/reducers/auth.reducer";

export const useApprovalTime = (
  filters: any,
  isInfoApprovalLoaded: boolean,
  projectFilterByParam: any,
  isLoadingUserInfo: boolean
) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(IsLoading);
  const projectsApprovalTime = useSelector(SelectProjectsApprovalTime);
  const managersApprovalTime = useSelector(SelectManagersApprovalTime);
  const { userList } = useSelector(selectOrderedBy);

  // times module admin
  const { userRolesTimesModule } = useSelector(actualUIState);
  const isSuperTimeAdmin = hasSuperTimeAdminAccess(userRolesTimesModule);
  const isSupervisorOrPO = isSupervisorOrProjectOwner(userRolesTimesModule);
  const { activeUserId } = useSelector(selectUserRoles);

  // ASUITE11-2614: Approve Time from the Project (link to The Hub) - FE
  // send params values to calculate in first load
  let filtersUpdated =
    projectFilterByParam && !filters.project
      ? { ...filters, project: projectFilterByParam }
      : filters;

  let filtersForRequest;
  if (isInfoApprovalLoaded && !isLoadingUserInfo) {
    filtersForRequest = preparedFiltersToApprove(
      filtersUpdated,
      isSuperTimeAdmin,
      isSupervisorOrPO,
      projectsApprovalTime,
      managersApprovalTime
    );
  }

  const loadTimeEntriesToApprove = useCallback(async () => {
    dispatch(setLoading(true));
    if (isInfoApprovalLoaded && !isLoadingUserInfo) {
      const results = await TimesheetsService.getTimeEntriesToApproveByUsers(
        filtersForRequest
      );
      dispatch(loadTimeEntriesInfo(results.data));
    }
  }, [dispatch, filters, isInfoApprovalLoaded, isLoadingUserInfo]);

  useEffect(() => {
    loadTimeEntriesToApprove();
  }, [loadTimeEntriesToApprove]);

  return {
    isLoading,
    activeUserId,
    userList,
  };
};
