import { useDispatch, useSelector } from "react-redux";
import {
  hasSuperTimeAdminAccess,
  hasTimeViewerAccess,
  isSupervisorOrProjectOwner,
  preparedFiltersWhoIsOut,
} from "../utils/timesUtils";
import {
  GetWhoIsOutUserList,
  IsLoading,
  loadWhoIsOutInfo,
  SelectProjectsWhoIsOut,
  setLoading,
} from "../redux/reducers/whoIsOutReport.reducer";
import { useCallback, useEffect } from "react";
import { TimesheetsService } from "../services/timesheets";

export const useWhoIsOutReport = (
  filters: any,
  isInfoWhoIsOutLoaded: boolean,
  userRolesTimesModule: any,
  isLoadingUserInfo: boolean
) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(IsLoading);
  const whoIsOutUserList = useSelector(GetWhoIsOutUserList);
  const projectsWhoIsOut = useSelector(SelectProjectsWhoIsOut);

  const isSuperTimeAdmin = hasSuperTimeAdminAccess(userRolesTimesModule);
  const isSupervisorOrPO = isSupervisorOrProjectOwner(userRolesTimesModule);
  const isTimeViewer = hasTimeViewerAccess(userRolesTimesModule);

  let filtersForRequest;
  if (isInfoWhoIsOutLoaded && !isLoadingUserInfo) {
    filtersForRequest = preparedFiltersWhoIsOut(
      filters,
      projectsWhoIsOut,
      isSuperTimeAdmin,
      isSupervisorOrPO,
      isTimeViewer
    );
  }

  const loadWhoIsOutResults = useCallback(async () => {
    dispatch(setLoading(true));
    if (isInfoWhoIsOutLoaded && !isLoadingUserInfo) {
      const results = await TimesheetsService.getWhoisOutInfo(
        filtersForRequest
      );
      dispatch(loadWhoIsOutInfo(results.data));
    }
  }, [dispatch, filters, isInfoWhoIsOutLoaded, isLoadingUserInfo]);

  useEffect(() => {
    loadWhoIsOutResults();
  }, [loadWhoIsOutResults]);

  return {
    isLoading,
    whoIsOutUserList,
    filters,
  };
};
