import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "../../SharedModule/redux/reducers/auth.reducer";
import { TelerikReport } from "../../SharedModule/components/TelerikReport";
import { getHostUrl } from "../../ProductsModule/utils/reportsUtils";
import {
  checkDataIsLoaded,
  hasSuperTimeAdminAccess,
  hasTimeViewerAccess,
} from "../utils/timesUtils";
import {
  actualUIState,
  setActiveTab,
} from "../../SharedModule/redux/reducers/uiShared.reducer";
import { useEffect } from "react";
import eventHandler from "../../SharedModule/utils/eventHandler";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";
import { Spinner } from "../../SharedModule/components/Spinner";
import {
  HOLIDAYS_ACCESS,
  ROUTE_TIMESHEETS_REPORTS,
} from "../../SharedModule/utils/constants";
import { verifyModuleAccess } from "../../SharedModule/utils/accessUtils";
import { ForbiddenPage } from "../../SharedModule/pages/ForbiddenPage";

export const ChangesToHolidaysReportPage = () => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  let parameters = {
    HostUrl: getHostUrl(),
    Auth: auth.accessToken,
  };
  let editors = {
    editors: {
      singleSelect: "COMBO_BOX",
      multiSelect: "COMBO_BOX",
    },
  };

  const MetaTimesInfo = useSelector(selectMetaTimesData);

  // times module admin
  const { userRolesTimesModule, isLoadingUserInfo } =
    useSelector(actualUIState);
  const isSuperTimeAdmin = hasSuperTimeAdminAccess(userRolesTimesModule);
  const isTimeViewer = hasTimeViewerAccess(userRolesTimesModule);

  useEffect(() => {
    document.title = "The Hub - Changes to Holidays Report";
    // should go in every times page
    if (!checkDataIsLoaded(MetaTimesInfo, isSuperTimeAdmin, isTimeViewer)) {
      eventHandler.dispatch("loadTimesStaticInfo", {
        isTimeAdmin: isSuperTimeAdmin,
        isTimeViewer: isTimeViewer,
      });
    }
    dispatch(setActiveTab(ROUTE_TIMESHEETS_REPORTS));
  }, [isSuperTimeAdmin, isTimeViewer]);

  return (
    <>
      {isLoadingUserInfo && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {!isLoadingUserInfo &&
        !verifyModuleAccess(true, HOLIDAYS_ACCESS, userRolesTimesModule) && (
          <ForbiddenPage />
        )}
      {!isLoadingUserInfo &&
        verifyModuleAccess(true, HOLIDAYS_ACCESS, userRolesTimesModule) && (
          <TelerikReport
            title="Changes to Holidays Report"
            reportName="ChangesToHolidays.trdp"
            parameters={parameters}
            parametersAreaVisible={true}
            parametersEditor={editors}
            backButtonText="Back to Time Reports"
            backButtonRoute={ROUTE_TIMESHEETS_REPORTS}
          />
        )}
    </>
  );
};
