import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimesheetsService } from "../../services/timesheets";
import { UserAssignees } from "../../../types";
import {
  Client,
  NonBillableReason,
  PayPeriods,
  ProjectManagerOrUser,
  ProjectOrWo,
  TaskType,
} from "../../types";
import { UsersService } from "../../../SharedModule/services/users";
import { addElementToArray } from "../../utils/timesUtils";

export const fetchMetaTimes = async (
  id: string,
  isTimeAdmin: boolean,
  isTimeViewer: boolean
) => {
  let userAssignees,
    payPeriods,
    taskTypesData,
    nonBillableReasonsData,
    filtersData,
    costCentersData,
    timeConfirmationData,
    costCentersTimeConfirmation;
  if (isTimeAdmin || isTimeViewer) {
    [
      userAssignees,
      payPeriods,
      taskTypesData,
      nonBillableReasonsData,
      filtersData,
      timeConfirmationData,
    ] = await Promise.all([
      UsersService.getUserAssignees(id),
      TimesheetsService.getPayPeriods(),
      TimesheetsService.getTaskTypes(),
      TimesheetsService.getNonBillableReasons(),
      TimesheetsService.getQueryFilters(),
      TimesheetsService.getTimeConfirmationFilters(),
    ]);

    costCentersData = [];
    costCentersTimeConfirmation = [];
    if (filtersData?.data.costCenters) {
      addElementToArray(costCentersData, filtersData.data.costCenters);
    }
    if (timeConfirmationData) {
      addElementToArray(
        costCentersTimeConfirmation,
        timeConfirmationData.costCenters
      );
    }
  } else {
    [
      userAssignees,
      payPeriods,
      taskTypesData,
      nonBillableReasonsData,
      filtersData,
    ] = await Promise.all([
      UsersService.getUserAssignees(id),
      TimesheetsService.getPayPeriods(),
      TimesheetsService.getTaskTypes(),
      TimesheetsService.getNonBillableReasons(),
      TimesheetsService.getQueryFilters(),
    ]);

    costCentersData = [];
    costCentersTimeConfirmation = null;
    if (filtersData?.data.costCenters) {
      addElementToArray(costCentersData, filtersData.data.costCenters);
    }
  }

  return {
    userAssignees:
      userAssignees?.data.map((assignee: UserAssignees) => ({
        value: assignee.id,
        label: assignee.fullName,
        email: assignee.email,
      })) || [],
    payPeriods: payPeriods.data.data,
    taskTypes:
      taskTypesData?.data.map((task: TaskType) => ({
        value: task.taskTypeId,
        label: task.taskTypeName,
        category: task.category,
      })) || [],
    nonBillableReasons:
      nonBillableReasonsData?.data.map((elem: NonBillableReason) => ({
        value: elem.id,
        label: elem.name,
      })) || [],
    costCenters: costCentersData || null,
    clients:
      filtersData.data?.clients.map((elem: Client) => ({
        value: elem.id,
        label: elem.name,
      })) || null,
    timeProjects:
      filtersData.data?.projects.map((elem: ProjectOrWo) => ({
        value: elem.entityId,
        label:
          elem.entityId > 0
            ? "Project " + elem.entityId + " - " + elem.entityName
            : elem.entityName,
        entityId: elem.entityId,
        categoryId: elem.categoryId,
        clientId: elem.clientId,
      })) || null,
    projectManagers:
      filtersData.data?.managers.map((elem: ProjectManagerOrUser) => ({
        value: elem.id,
        label: elem.fullName,
        projectIds: elem.projectIds,
      })) || null,
    timeUsers:
      filtersData.data?.timeUsers.map((elem: ProjectManagerOrUser) => ({
        value: elem.id,
        label: elem.fullName,
        isActive: elem.isActive,
        email: elem.email,
      })) || null,
    countries:
      filtersData.data?.countries.map((elem: string) => ({
        value: elem,
        label: elem,
      })) || null,
    costCentersTimeConfirmation: costCentersTimeConfirmation || null,
  };
};

export type MetaTimesState = {
  userAssignees: Array<any> | null;
  payPeriods: PayPeriods | null;
  taskTypes: Array<any> | null;
  nonBillableReasons: Array<any> | null;
  costCenters: Array<any> | null;
  clients: Array<any> | null;
  timeProjects: Array<any> | null;
  projectManagers: Array<any> | null;
  timeUsers: Array<any> | null;
  countries: Array<any> | null;
  costCentersTimeConfirmation: Array<any> | null;
};

type SetItem = Partial<MetaTimesState>;

const initialState: MetaTimesState = {
  userAssignees: null,
  payPeriods: null,
  taskTypes: null,
  nonBillableReasons: null,
  costCenters: null,
  clients: null,
  timeProjects: null,
  projectManagers: null,
  timeUsers: null,
  countries: null,
  costCentersTimeConfirmation: null,
};

export const metaSlice = createSlice({
  name: "metaTimes",
  initialState,
  reducers: {
    // Remember Redux Toolkit allows us to write "mutating" logic in reducers.
    setMetaTimes: (state: MetaTimesState, action: PayloadAction<SetItem>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setMetaTimes } = metaSlice.actions;

// Selectors
export const selectUserAssignees = ({
  metaTimes,
}: {
  metaTimes: MetaTimesState;
}) => metaTimes.userAssignees;

export const selectPayPeriods = ({
  metaTimes,
}: {
  metaTimes: MetaTimesState;
}) => metaTimes.payPeriods;

export const selectTaskTypes = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.taskTypes;

export const selectNonBillableReasons = ({
  metaTimes,
}: {
  metaTimes: MetaTimesState;
}) => metaTimes.nonBillableReasons;

export const selectCostCenters = ({
  metaTimes,
}: {
  metaTimes: MetaTimesState;
}) => metaTimes.costCenters;

export const selectClients = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.clients;

export const selectTimeProjects = ({
  metaTimes,
}: {
  metaTimes: MetaTimesState;
}) => metaTimes.timeProjects;

export const selectProjectManagers = ({
  metaTimes,
}: {
  metaTimes: MetaTimesState;
}) => metaTimes.projectManagers;

export const selectTimeUsers = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.timeUsers;

export const selectCountries = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.countries;

export const selectCostCentersTimeConfirmation = ({
  metaTimes,
}: {
  metaTimes: MetaTimesState;
}) => metaTimes.costCentersTimeConfirmation;

export const selectMetaTimesData = (state: { metaTimes: MetaTimesState }) => {
  return {
    userAssignees: selectUserAssignees(state),
    payPeriods: selectPayPeriods(state),
    taskTypes: selectTaskTypes(state),
    nonBillableReasons: selectNonBillableReasons(state),
    costCenters: selectCostCenters(state),
    clients: selectClients(state),
    timeProjects: selectTimeProjects(state),
    projectManagers: selectProjectManagers(state),
    timeUsers: selectTimeUsers(state),
    countries: selectCountries(state),
    costCentersTimeConfirmation: selectCostCentersTimeConfirmation(state),
  };
};

export default metaSlice.reducer;
