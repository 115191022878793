import {
  ROUTE_APPROVE_TIME_TIMESHEETS,
  ROUTE_QUERY_TIMESHEETS,
  ROUTE_TIMESHEETS,
  ROUTE_TIMESHEETS_REPORTS,
  TIME_ADMIN_ACCESS,
  TIME_BASIC_ACCESS,
  TIME_VIEWER_AND_ADMIN_ACCESS,
  TIME_SUPER_ADMIN_ACCESS,
} from "../../SharedModule/utils/constants";
import { LeftNavBar } from "../../SharedModule/components/LeftNavBar";
import { LeftNavBarSection } from "../../SharedModule/components/LeftNavBarSection";
import { useSelector } from "react-redux";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";

export const LeftNavBarTimesheets = () => {
  const { userRolesTimesModule } = useSelector(actualUIState);

  return (
    <LeftNavBar>
      <LeftNavBarSection
        mainTitle="Time"
        biIconName="BiAlarm"
        moduleAccess={false}
        moduleRoles={userRolesTimesModule}
        listOfLinks={[
          {
            text: "Enter Time",
            route: ROUTE_TIMESHEETS,
            moduleAccess: false,
          },
          {
            text: "Approve time",
            route: ROUTE_APPROVE_TIME_TIMESHEETS,
            moduleAccess: true,
            role: TIME_ADMIN_ACCESS,
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Reports"
        biIconName="BiBarChartAlt2"
        moduleAccess={false}
        moduleRoles={userRolesTimesModule}
        listOfLinks={[
          {
            text: "Query",
            route: ROUTE_QUERY_TIMESHEETS,
            moduleAccess: false,
          },
          {
            text: "Time Reports",
            route: ROUTE_TIMESHEETS_REPORTS,
            moduleAccess: true,
            role: TIME_VIEWER_AND_ADMIN_ACCESS,
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Reimbursements"
        biIconName="BiDollar"
        moduleAccess={true}
        role={TIME_BASIC_ACCESS}
        moduleRoles={userRolesTimesModule}
        listOfLinks={[
          {
            text: "Reimbursements",
            route:
              process.env.REACT_APP_ASUITE_BASE_URI +
              "/Timesheets/reimbreport.asp",
            moduleAccess: true,
            role: TIME_BASIC_ACCESS,
          },
          {
            text: "Expense Report",
            route:
              process.env.REACT_APP_ASUITE_BASE_URI +
              "/Timesheets/Expense_Report.asp",
            moduleAccess: true,
            role: TIME_BASIC_ACCESS,
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Administration"
        biIconName="BiShieldQuarter"
        moduleAccess={true}
        role={TIME_SUPER_ADMIN_ACCESS}
        moduleRoles={userRolesTimesModule}
        listOfLinks={[
          {
            text: "Administrative Functions",
            route:
              process.env.REACT_APP_ASUITE_BASE_URI +
              "/Timesheets/timesheetsAdmin.asp",
            moduleAccess: true,
            role: TIME_SUPER_ADMIN_ACCESS,
          },
        ]}
      />
    </LeftNavBar>
  );
};
