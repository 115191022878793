import { useEffect } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useDispatch, useSelector } from "react-redux";
import {
  actualUIState,
  setActiveTab,
} from "../../SharedModule/redux/reducers/uiShared.reducer";
import {
  HOLIDAYS_ACCESS,
  ROUTE_BAMBOO_TIME_OFF_REPORT,
  ROUTE_CHANGE_TO_HOLIDAYS_REPORT,
  ROUTE_MEAL_BREAK_REPORT,
  ROUTE_TIME_CONFIRMATION_REPORT,
  ROUTE_TIMESHEETS_REPORTS,
  ROUTE_WHO_IS_OUT_REPORT,
  TIME_SUPER_ADMIN_ACCESS,
  TIME_VIEWER_AND_ADMIN_ACCESS,
} from "../../SharedModule/utils/constants";
import DynamicBiIcon from "./../../SharedModule/components/DynamicBiIcon";
import eventHandler from "../../SharedModule/utils/eventHandler";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";
import {
  checkDataIsLoaded,
  hasTimeAdminAccess,
  hasTimeViewerAccess,
} from "../utils/timesUtils";
import Footer from "../../SharedModule/components/Footer";
import { verifyModuleAccess } from "../../SharedModule/utils/accessUtils";
import { Spinner } from "../../SharedModule/components/Spinner";
import { ForbiddenPage } from "../../SharedModule/pages/ForbiddenPage";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";

export const TimesReportsPage = () => {
  const { trackPageView } = useMatomo();
  const dispatch = useDispatch();

  const MetaTimesInfo = useSelector(selectMetaTimesData);

  // times module admin
  const { userRolesTimesModule, isLoadingUserInfo } =
    useSelector(actualUIState);
  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);
  const isTimeViewer = hasTimeViewerAccess(userRolesTimesModule);

  useEffect(() => {
    document.title = "The Hub - Time Reports Page";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    // should go in every times page
    if (!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin, isTimeViewer)) {
      eventHandler.dispatch("loadTimesStaticInfo", {
        isTimeAdmin: isTimeAdmin,
        isTimeViewer: isTimeViewer,
      });
    }

    dispatch(setActiveTab(ROUTE_TIMESHEETS_REPORTS));
  }, [trackPageView, isTimeAdmin, isTimeViewer]);

  return (
    <>
      {isLoadingUserInfo && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {!isLoadingUserInfo &&
        !verifyModuleAccess(
          true,
          TIME_VIEWER_AND_ADMIN_ACCESS,
          userRolesTimesModule
        ) && <ForbiddenPage />}
      {!isLoadingUserInfo &&
        verifyModuleAccess(
          true,
          TIME_VIEWER_AND_ADMIN_ACCESS,
          userRolesTimesModule
        ) && (
          <div className="content">
            <h2 className="mb-5">Time Reports</h2>

            {verifyModuleAccess(
              true,
              TIME_VIEWER_AND_ADMIN_ACCESS,
              userRolesTimesModule
            ) && (
              <div className="d-flex flex-column">
                <h4 className="mb-0">Overtime / Undertime</h4>
                <div className="d-flex flex-row flex-wrap">
                  <Link
                    className="card card-width-reports text-decoration-none entry-hour-box-background px-4 pt-3 pb-3 my-3"
                    to={ROUTE_TIME_CONFIRMATION_REPORT}
                  >
                    <div className="d-flex flex-row">
                      <div className="left">
                        <div className="d-flex flex-row">
                          <DynamicBiIcon
                            icon="BiBarChartAlt2"
                            className="icon-time-reports ms-1 me-3"
                          />
                          <div className="card-title-time-reports">
                            Overtime Hours report
                          </div>
                        </div>
                        <div className="d-flex flex-row mt-3 ms-1">
                          Confirm all the time has been entered in a period, by
                          team or project
                        </div>
                      </div>
                      <div className="right-column-reports">
                        <BiChevronRight className="arrow-icon-nohover" />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}

            <div className="d-flex flex-column mt-4">
              <h4 className="mb-0">Time Off</h4>
              <div className="d-flex flex-row flex-wrap">
                <Link
                  className="card card-width-reports text-decoration-none entry-hour-box-background px-4 pt-3 pb-3 my-3 me-5"
                  to={ROUTE_WHO_IS_OUT_REPORT}
                >
                  <div className="d-flex flex-row">
                    <div className="left">
                      <div className="d-flex flex-row">
                        <DynamicBiIcon
                          icon="BiBarChartAlt2"
                          className="icon-time-reports ms-1 me-3"
                        />
                        <div className="card-title-time-reports">
                          Who is out in the future
                        </div>
                      </div>
                      <div className="d-flex flex-row mt-3 ms-1">
                        Preview the holidays and time off for a group of users
                      </div>
                    </div>
                    <div className="right-column-reports">
                      <BiChevronRight className="arrow-icon-nohover" />
                    </div>
                  </div>
                </Link>

                {verifyModuleAccess(
                  true,
                  HOLIDAYS_ACCESS,
                  userRolesTimesModule
                ) && (
                  <Link
                    className="card card-width-reports text-decoration-none entry-hour-box-background px-4 pt-3 pb-3 my-3 me-5"
                    to={ROUTE_CHANGE_TO_HOLIDAYS_REPORT}
                  >
                    <div className="d-flex flex-row">
                      <div className="left">
                        <div className="d-flex flex-row">
                          <DynamicBiIcon
                            icon="BiBarChartAlt2"
                            className="icon-time-reports ms-1 me-3"
                          />
                          <div className="card-title-time-reports">
                            Changes to holidays
                          </div>
                        </div>
                        <div className="d-flex flex-row mt-3 ms-1">
                          Audit Log of changes made by Time Admins to Holidays,
                          by user
                        </div>
                      </div>
                      <div className="right-column-reports">
                        <BiChevronRight className="arrow-icon-nohover" />
                      </div>
                    </div>
                  </Link>
                )}

                {verifyModuleAccess(
                  true,
                  TIME_SUPER_ADMIN_ACCESS,
                  userRolesTimesModule
                ) && (
                  <Link
                    className="card card-width-reports text-decoration-none entry-hour-box-background px-4 pt-3 pb-3 my-3"
                    to={ROUTE_BAMBOO_TIME_OFF_REPORT}
                  >
                    <div className="d-flex flex-row">
                      <div className="left">
                        <div className="d-flex flex-row">
                          <DynamicBiIcon
                            icon="BiBarChartAlt2"
                            className="icon-time-reports ms-1 me-3"
                          />
                          <div className="card-title-time-reports">
                            BambooHR Export report
                          </div>
                        </div>
                        <div className="d-flex flex-row mt-3 ms-1">
                          Audit log of the daily import of Time Off from
                          BambooHR
                        </div>
                      </div>
                      <div className="right-column-reports">
                        <BiChevronRight className="arrow-icon-nohover" />
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </div>

            {verifyModuleAccess(
              true,
              TIME_SUPER_ADMIN_ACCESS,
              userRolesTimesModule
            ) && (
              <div className="d-flex flex-column mt-4">
                <h4 className="mb-0">Payroll</h4>
                <div className="d-flex flex-row flex-wrap">
                  <Link
                    className="card card-width-reports text-decoration-none entry-hour-box-background px-4 pt-3 pb-3 my-3"
                    to={ROUTE_MEAL_BREAK_REPORT}
                  >
                    <div className="d-flex flex-row">
                      <div className="left">
                        <div className="d-flex flex-row">
                          <DynamicBiIcon
                            icon="BiBarChartAlt2"
                            className="icon-time-reports ms-1 me-3"
                          />
                          <div className="card-title-time-reports">
                            Meal Break report
                          </div>
                        </div>
                        <div className="d-flex flex-row mt-3 ms-1">
                          US Hourly employees that are expected to enter a daily
                          Meal Break
                        </div>
                      </div>
                      <div className="right-column-reports">
                        <BiChevronRight className="arrow-icon-nohover" />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}

            <Footer />
          </div>
        )}
    </>
  );
};
