import {
  ROLE_NOT_OWNERS,
  ROUTE_INVOICES_LIST,
  ROUTE_PROFFESSIONAL_SERVICES_QUERY,
  ROUTE_RECURRING_QUERY,
} from "../../SharedModule/utils/constants";
import { LeftNavBar } from "../../SharedModule/components/LeftNavBar";
import { LeftNavBarSection } from "../../SharedModule/components/LeftNavBarSection";
import { selectUserRoles } from "../../SharedModule/redux/reducers/auth.reducer";
import { useSelector } from "react-redux";

export const LeftNavBarBilling = () => {
  const { userRoles } = useSelector(selectUserRoles);
  /* TODO: here we are using endpoint 'api/users/Security/Roles/Mine' that brings all roles for user except time's roles. 
  Also we create functions to get all roles for time and products module */
  let partialBillingRoles = userRoles?.map((elem) => elem.name);

  return (
    <LeftNavBar>
      <LeftNavBarSection
        mainTitle="Manage Billing"
        biIconName="BiMoneyWithdraw"
        moduleAccess={true}
        role={ROLE_NOT_OWNERS}
        moduleRoles={partialBillingRoles}
        listOfLinks={[
          {
            text: "Professional Services",
            route: ROUTE_PROFFESSIONAL_SERVICES_QUERY,
            moduleAccess: true,
            role: ROLE_NOT_OWNERS,
          },
          {
            text: "Recurring Billing",
            route: ROUTE_RECURRING_QUERY,
            moduleAccess: true,
            role: ROLE_NOT_OWNERS,
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Reports"
        biIconName="BiBarChartAlt2"
        moduleAccess={false}
        listOfLinks={[
          { text: "Invoices", route: ROUTE_INVOICES_LIST },
          {
            text: "Billing Reports",
            route: "/billing/billingreports.asp",
            asuiteLink: true,
          },
          {
            text: "View Price Levels",
            route: "/billing/billpricelevels.asp",
            asuiteLink: true,
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Administration"
        biIconName="BiShieldQuarter"
        moduleAccess={false}
        listOfLinks={[
          {
            text: "Administrative Functions",
            route: "/billing/billingmenu.asp",
            asuiteLink: true,
          },
          {
            text: "Edit Recurring Biling Items",
            route: "/billing/AdminBillingRecurring.asp",
            asuiteLink: true,
          },
          {
            text: "Pay Commissions",
            route: "/billing/BillPayCommissionsSelect.asp",
            asuiteLink: true,
          },
        ]}
      />
    </LeftNavBar>
  );
};
