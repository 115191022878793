import { DateTime } from "luxon";

export const currencyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
}).format;

export const dateFormat = (date: string) =>
  DateTime.fromISO(date, { zone: "utc" }).setLocale("en-US").toFormat("DD");

export const nameDayDateFormat = (date: string) =>
  DateTime.fromISO(date, { zone: "utc" })
    .setLocale("en-US")
    .toFormat("ccc, MMM d");

export const nameDayCompleteDateFormat = (date: string) =>
  DateTime.fromISO(date, { zone: "utc" })
    .setLocale("en-US")
    .toFormat("ccc, LL/dd/yyyy");

export const numDayShortMonthDateFormat = (date: string) =>
  DateTime.fromISO(date, { zone: "utc" }).setLocale("en-US").toFormat("d MMM");

export const numDayShortMonthWithYearDateFormat = (date: string) =>
  DateTime.fromISO(date, { zone: "utc" })
    .setLocale("en-US")
    .toFormat("d MMM yy");

export const dateFormatWithHours = (date: string) =>
  DateTime.fromISO(date, {
    zone: "America/New_York",
    locale: "en-US",
  }).toFormat("LLL d, yyyy 'at' t");

export const dateFormatLocal = (date: string) =>
  DateTime.fromISO(date).setLocale("en-US").toFormat("DD");

export const dateFormatLocalToSort = (date: string) =>
  DateTime.fromISO(date, {
    zone: "America/New_York",
    locale: "en-US",
  }).toFormat("yyyy/LL/dd, t");

export const dateFormatEntryEdit = (date: string) =>
  DateTime.fromISO(date, {
    zone: "utc",
    locale: "en-US",
  }).toFormat("LL/dd/yyyy");

export const timeAmountFormat = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format;

export const quantityFormat = timeAmountFormat;

export const formatForDropdowns: <T extends { id: number }>(
  key: Exclude<keyof T, "id">
) => (item: T) => { value: string; label: string } = (key) => (item) => ({
  value: "" + item.id,
  label: "" + item[key],
});

export const activityLogFormat = (activity: string) =>
  // remove Invoice word just to avoid redundance
  activity
    .replace("Invoice", "")
    .match(/[A-Z][a-z]+|[0-9]+/g)
    ?.join(" ");
