import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useEffect } from "react";
import Header from "./SharedModule/components/Header";
import { ProfessionalServicesQuery } from "./../src/BillingModule/pages/ProfessionalServicesQuery";
import { Invoices } from "./../src/BillingModule/pages/Invoices";
import { Invoice } from "./../src/BillingModule/pages/Invoice";
import { BillableItemsResults } from "./../src/BillingModule/pages/BillableItemsResults";
import { InvoiceQuery } from "./../src/BillingModule/pages/InvoiceQuery";
import { InvoiceSearch } from "./../src/BillingModule/pages/InvoiceSearch";
import { RecurringQuery } from "./../src/BillingModule/pages/RecurringQuery";
import { ForbiddenPage } from "./SharedModule/pages/ForbiddenPage";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { Auth } from "./SharedModule/components/Auth";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMetaBilling,
  setMetaBilling,
} from "./BillingModule/redux/reducers/metaBilling.reducer";
import {
  fetchMetaProducts,
  setMetaProducts,
} from "./ProductsModule/redux/reducers/metaProducts.reducer";
import {
  fetchMetaTimes,
  setMetaTimes,
} from "./TimesModule/redux/reducers/metaTimes.reducer";

import {
  selectAuth,
  selectUserRoles,
} from "./SharedModule/redux/reducers/auth.reducer";
import { AccessControl } from "./SharedModule/components/AccessControl";
import {
  ROLE_NOT_OWNERS,
  ROUTE_BILLABLE_ITEMS_RESULTS,
  ROUTE_INVOICES_DETAIL,
  ROUTE_INVOICES_LIST,
  ROUTE_INVOICES_SEARCH,
  ROUTE_INVOICES_FILTERS,
  ROUTE_PROFFESSIONAL_SERVICES_QUERY,
  ROUTE_RECURRING_QUERY,
  ROUTE_PRODUCTS_LIST,
  ROUTE_PRODUCT_SPOTLIGHT,
  ROUTE_PRODUCT_KEYCONTACTS,
  ROUTE_PRODUCT_KEYCONTACTS_DETAILS,
  ROUTE_PRODUCT_SUCCESSCRITERIA,
  ROUTE_PRODUCT_SPOTLIGHT_SUMMARY_REPORT,
  ROUTE_NOT_FOUND_PAGE,
  ROUTE_PRODUCT_SPOTLIGHT_HISTORY,
  ROLE_BILLING_MODULE,
  ROUTE_PRODUCT_NEW_KEYCONTACTS_DETAILS,
  ROUTE_PRODUCT_KEYCONTACTS_CONFIDENCE_HISTORY,
  ROUTE_PRODUCT_ADVOCACY_DETAIL_REPORT,
  ROUTE_PRODUCT_ADVOCACY_SUMMARY_REPORT,
  ROUTE_PRODUCT_RELATIONSHIP_UPDATES_REPORT,
  ROUTE_PRODUCT_RELATIONSHIP_REVIEW_REPORT,
  ROUTE_FORBIDDEN_PAGE,
  ROUTE_THEHUB_ADMIN,
  ROUTE_DELETE_PRODUCT_PAGE,
  BILLING_MODULE,
  PRODUCTS_MODULE,
  ADMIN_MODULE,
  NO_MODULE,
  ROUTE_LOG_OUT,
  ROUTE_TIMESHEETS,
  TIMES_MODULE,
  ROUTE_QUERY_TIMESHEETS,
  ROUTE_QUERY_RESULTS_TIMESHEETS,
  ROUTE_HOME,
  ROUTE_SAMPLE_REPORT,
  ROUTE_APPROVE_TIME_TIMESHEETS,
  ROUTE_ACCESS_CONTROL_REPORT_BY_USER,
  ROUTE_ACCESS_CONTROL_REPORT_BY_JIRA_PROJECT,
  ROUTE_ACCESS_CONTROL_REPORT_BY_GITLAB_PROJECT,
  ADMIN_ACCESS,
  ROUTE_TIMESHEETS_REPORTS,
  ROUTE_APPROVE_TIME_TIMESHEETS_BY_USER,
  ROUTE_BAMBOO_TIME_OFF_REPORT,
  ROUTE_TIME_CONFIRMATION_REPORT,
  ROUTE_TIME_CONFIRMATION_RESULTS,
  ROUTE_WHO_IS_OUT_REPORT,
  ROUTE_MEAL_BREAK_REPORT,
  ROUTE_CHANGE_TO_HOLIDAYS_REPORT,
  ROLE_BILLING_MODULE_WITH_RECURRING_ADMIN,
} from "./SharedModule/utils/constants";
import { ToastPortal } from "./SharedModule/components/ToastPortal";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { SpotlightSummaryReportPage } from "./ProductsModule/pages/SpotlightSummaryReport";
import { ProductsListPage } from "./ProductsModule/pages/ProductsListPage";
import { SpotlightPage } from "./ProductsModule/pages/SpotlightPage";
import { KeyContactsPage } from "./ProductsModule/pages/KeyContactsPage";
import { KeyContactDetailPage } from "./ProductsModule/pages/KeyContactDetailPage";
import { SuccessCriteriaPage } from "./ProductsModule/pages/SuccessCriteriaPage";
import { NotFoundPage } from "./SharedModule/pages/NotFoundPage";
import { SpotlightHistoryPage } from "./ProductsModule/pages/SpotlightHistoryPage";
import { NewKeyContactPage } from "./ProductsModule/pages/NewKeyContactPage";
import { ConfidenceHistoryPage } from "./ProductsModule/pages/ConfidenceHistoryPage";
import { AdvocacyDetailReportPage } from "./ProductsModule/pages/AdvocacyDetailReport";
import { AdvocacySummaryReportPage } from "./ProductsModule/pages/AdvocacySummaryReport";
import { RelationshipUpdatesReportPage } from "./ProductsModule/pages/RelationshipUpdatesReport";
import { AdminPage } from "./SharedModule/pages/AdminPage";
import {
  setIsLoadingExternalBilling,
  setIsLoadingExternalProducts,
} from "./ProductsModule/redux/reducers/products.reducer";
import { DeleteProductPage } from "./ProductsModule/pages/DeleteProductPage";
import { RelationshipReviewReportPage } from "./ProductsModule/pages/RelationshipReviewReport";
import { HomePage } from "./SharedModule/pages/HomePage";
import { LeftNavBarProducts } from "./ProductsModule/components/LeftNavBarProducts";
import { LeftNavBarBilling } from "./BillingModule/components/LeftNavBarBilling";
import { LeftNavBarAdmin } from "./SharedModule/components/LeftNavBarAdmin";
import { LogOutPage } from "./SharedModule/pages/LogOutPage";
import { actualUIState } from "./SharedModule/redux/reducers/uiShared.reducer";
import eventHandler from "./SharedModule/utils/eventHandler";
import { TimesPage } from "./TimesModule/pages/TimesPage";
import { LeftNavBarTimesheets } from "./TimesModule/components/LeftNavBarTimesheets";
import { TimesQueryPage } from "./TimesModule/pages/TimesQueryPage";
import { QueryResultsPage } from "./TimesModule/pages/QueryResultsPage";
import { MaintenancePage } from "./SharedModule/pages/MaintenancePage";
import { hasAccessDuringMaintenance } from "./SharedModule/utils/maintenanceHelper";
import { SampleReportPage } from "./SharedModule/pages/SampleReportPage";
import { ApproveTimePage } from "./TimesModule/pages/ApproveTimePage";
import { AccessControlReportByUser } from "./SharedModule/pages/AccessControlReportPageByUser";
import { AccessControlReportPageByJiraProject } from "./SharedModule/pages/AccessControlReportPageByJiraProject";
import { AccessControlReportPageByGitlabProject } from "./SharedModule/pages/AccessControlReportPageByGitlabProject";
import { TimesReportsPage } from "./TimesModule/pages/TimesReportsPage";
import { ApproveTimeByUserPage } from "./TimesModule/pages/ApproveTimeByUserPage";
import { BambooTimeOffReportPage } from "./TimesModule/pages/BambooHRTimeOffReportPage";
import { TimesConfirmationReportsPage } from "./TimesModule/pages/TimesConfirmationReportPage";
import { TimesConfirmationResultsPage } from "./TimesModule/pages/TimesConfirmationResultsPage";
import { WhoIsOutReportsPage } from "./TimesModule/pages/WhoIsOutReportPage";
import { MealBreakReportsPage } from "./TimesModule/pages/MealBreakReportPage";
import { ChangesToHolidaysReportPage } from "./TimesModule/pages/ChangesToHolidayReportPage";
import {
  hasBillingRole,
  isOnlyRecurringBillingAdminRole,
} from "./SharedModule/utils/accessUtils";

const DataWrapper = ({ children, auth }: any) => {
  const dispatch = useDispatch();

  // Set userId in Matomo
  const { accounts } = useMsal();
  const { pushInstruction } = useMatomo();
  const navigate = useNavigate();

  const handleEvent = () => {
    eventHandler.dispatch("authLogin", {});
    navigate(ROUTE_HOME);
  };

  useEffect(() => {
    // Set userId in Matomo
    pushInstruction("setUserId", accounts[0].username);

    const loadMetaBilling = async () => {
      const metaBilling = await fetchMetaBilling();
      localStorage.setItem(
        "metaBillingUsers",
        JSON.stringify(metaBilling.users)
      );
      dispatch(setMetaBilling(metaBilling));
      dispatch(setIsLoadingExternalBilling(false));
    };

    const loadMetaProducts = async () => {
      const metaProducts = await fetchMetaProducts();
      dispatch(setMetaProducts(metaProducts));
      dispatch(setIsLoadingExternalProducts(false));
    };

    const loadMetaTimes = async (
      id: string,
      isTimeAdmin: boolean,
      isTimeViewer: boolean
    ) => {
      const metaTimes = await fetchMetaTimes(id, isTimeAdmin, isTimeViewer);
      dispatch(setMetaTimes(metaTimes));
      localStorage.setItem(
        "metaTimeProjects",
        JSON.stringify(metaTimes.timeProjects)
      );
      localStorage.setItem(
        "metaTaskTypes",
        JSON.stringify(metaTimes.taskTypes)
      );
      localStorage.setItem(
        "metaTimesUsers",
        JSON.stringify(metaTimes.timeUsers)
      );
    };

    eventHandler.on("loadTimesStaticInfo", (data) => {
      loadMetaTimes(
        accounts[0].localAccountId,
        data.isTimeAdmin,
        data.isTimeViewer
      );
    });

    if (auth.isAuthenticated) {
      loadMetaBilling();
      loadMetaProducts();
    }

    window.addEventListener("popstate", handleEvent);

    return () => window.removeEventListener("popstate", handleEvent);
  }, [auth.isAuthenticated, dispatch]);
  return children;
};

function App() {
  const { userRoles, activeUserId, activeUserEmail } =
    useSelector(selectUserRoles);
  const hasBillingAccess = hasBillingRole(userRoles);
  const isOnlyRecurringBillingAdmin =
    isOnlyRecurringBillingAdminRole(userRoles);
  const auth = useSelector(selectAuth);
  const location = useLocation();
  const { isLeftNavBarFixed } = useSelector(actualUIState);

  let actualModule: string = location.pathname
    .toLowerCase()
    .startsWith(ROUTE_INVOICES_LIST)
    ? BILLING_MODULE
    : location.pathname.toLowerCase().startsWith(ROUTE_PRODUCTS_LIST) ||
      location.pathname.toLowerCase().startsWith("/access-control-report-")
    ? PRODUCTS_MODULE
    : location.pathname.toLowerCase().startsWith(ROUTE_THEHUB_ADMIN)
    ? ADMIN_MODULE
    : location.pathname.startsWith(ROUTE_TIMESHEETS)
    ? TIMES_MODULE
    : NO_MODULE;

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <ToastPortal autoClose />
      <DataWrapper auth={auth} activeUserId={activeUserId}>
        <Auth>
          {hasAccessDuringMaintenance(activeUserEmail) && <Header />}
          <div className="main-content">
            <div className="d-flex">
              {hasAccessDuringMaintenance(activeUserEmail) &&
                actualModule === BILLING_MODULE &&
                !isOnlyRecurringBillingAdmin && <LeftNavBarBilling />}
              {hasAccessDuringMaintenance(activeUserEmail) &&
                actualModule === PRODUCTS_MODULE && <LeftNavBarProducts />}
              {hasAccessDuringMaintenance(activeUserEmail) &&
                actualModule === ADMIN_MODULE && <LeftNavBarAdmin />}
              {hasAccessDuringMaintenance(activeUserEmail) &&
                actualModule === TIMES_MODULE && <LeftNavBarTimesheets />}
              <div
                className={`detail-page ${isLeftNavBarFixed ? "fixed" : ""}`}
              >
                {!hasAccessDuringMaintenance(activeUserEmail) && (
                  <Routes>
                    {auth.isAuthenticated && (
                      <Route path="*" element={<MaintenancePage />} />
                    )}
                    {!auth.isAuthenticated && (
                      <Route path="*" element={<LogOutPage />} />
                    )}
                  </Routes>
                )}
                {hasAccessDuringMaintenance(activeUserEmail) && (
                  <Routes>
                    {/* Home Page */}
                    {auth.isAuthenticated && (
                      <Route path={ROUTE_HOME} element={<HomePage />} />
                    )}
                    {/* Billing module routes */}
                    <Route
                      path={ROUTE_INVOICES_LIST}
                      element={
                        <AccessControl
                          allowedRoles={
                            ROLE_BILLING_MODULE_WITH_RECURRING_ADMIN
                          }
                          fallback={
                            hasBillingAccess ? (
                              <ForbiddenPage />
                            ) : (
                              <Navigate to={ROUTE_HOME} replace />
                            )
                          }
                        >
                          <Invoices />
                        </AccessControl>
                      }
                    />
                    <Route
                      path={ROUTE_PROFFESSIONAL_SERVICES_QUERY}
                      element={
                        <AccessControl
                          allowedRoles={ROLE_NOT_OWNERS}
                          fallback={
                            hasBillingAccess || isOnlyRecurringBillingAdmin ? (
                              <ForbiddenPage />
                            ) : (
                              <Navigate to={ROUTE_HOME} replace />
                            )
                          }
                        >
                          <ProfessionalServicesQuery />
                        </AccessControl>
                      }
                    />
                    <Route
                      path={ROUTE_RECURRING_QUERY}
                      element={
                        <AccessControl
                          allowedRoles={ROLE_NOT_OWNERS}
                          fallback={
                            hasBillingAccess || isOnlyRecurringBillingAdmin ? (
                              <ForbiddenPage />
                            ) : (
                              <Navigate to={ROUTE_HOME} replace />
                            )
                          }
                        >
                          <RecurringQuery />
                        </AccessControl>
                      }
                    />
                    <Route
                      path={ROUTE_BILLABLE_ITEMS_RESULTS}
                      element={
                        <AccessControl
                          allowedRoles={ROLE_NOT_OWNERS}
                          fallback={
                            hasBillingAccess || isOnlyRecurringBillingAdmin ? (
                              <ForbiddenPage />
                            ) : (
                              <Navigate to={ROUTE_HOME} replace />
                            )
                          }
                        >
                          <BillableItemsResults />
                        </AccessControl>
                      }
                    />
                    <Route
                      path={ROUTE_INVOICES_DETAIL}
                      element={
                        <AccessControl
                          allowedRoles={ROLE_BILLING_MODULE}
                          fallback={
                            hasBillingAccess || isOnlyRecurringBillingAdmin ? (
                              <ForbiddenPage />
                            ) : (
                              <Navigate to={ROUTE_HOME} replace />
                            )
                          }
                        >
                          <Invoice />
                        </AccessControl>
                      }
                    />
                    <Route
                      path={ROUTE_INVOICES_SEARCH}
                      element={
                        <AccessControl
                          allowedRoles={ROLE_BILLING_MODULE}
                          fallback={
                            hasBillingAccess || isOnlyRecurringBillingAdmin ? (
                              <ForbiddenPage />
                            ) : (
                              <Navigate to={ROUTE_HOME} replace />
                            )
                          }
                        >
                          <InvoiceSearch />
                        </AccessControl>
                      }
                    />
                    <Route
                      path={ROUTE_INVOICES_FILTERS}
                      element={
                        <AccessControl
                          allowedRoles={ROLE_BILLING_MODULE}
                          fallback={
                            hasBillingAccess || isOnlyRecurringBillingAdmin ? (
                              <ForbiddenPage />
                            ) : (
                              <Navigate to={ROUTE_HOME} replace />
                            )
                          }
                        >
                          <InvoiceQuery />
                        </AccessControl>
                      }
                    />
                    {/* Products module routes */}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_PRODUCTS_LIST}
                        element={<ProductsListPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_PRODUCT_SPOTLIGHT}
                        element={<SpotlightPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_PRODUCT_SPOTLIGHT_HISTORY}
                        element={<SpotlightHistoryPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_PRODUCT_KEYCONTACTS}
                        element={<KeyContactsPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_PRODUCT_NEW_KEYCONTACTS_DETAILS}
                        element={<NewKeyContactPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_PRODUCT_KEYCONTACTS_DETAILS}
                        element={<KeyContactDetailPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_PRODUCT_KEYCONTACTS_CONFIDENCE_HISTORY}
                        element={<ConfidenceHistoryPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_PRODUCT_SUCCESSCRITERIA}
                        element={<SuccessCriteriaPage />}
                      />
                    )}
                    {/* Products reports */}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_PRODUCT_SPOTLIGHT_SUMMARY_REPORT}
                        element={<SpotlightSummaryReportPage />}
                      />
                    )}

                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_PRODUCT_ADVOCACY_SUMMARY_REPORT}
                        element={<AdvocacySummaryReportPage />}
                      />
                    )}

                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_PRODUCT_ADVOCACY_DETAIL_REPORT}
                        element={<AdvocacyDetailReportPage />}
                      />
                    )}

                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_PRODUCT_RELATIONSHIP_UPDATES_REPORT}
                        element={<RelationshipUpdatesReportPage />}
                      />
                    )}

                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_PRODUCT_RELATIONSHIP_REVIEW_REPORT}
                        element={<RelationshipReviewReportPage />}
                      />
                    )}

                    {/* Times module routes */}
                    {auth.isAuthenticated && (
                      <Route path={ROUTE_TIMESHEETS} element={<TimesPage />} />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_TIMESHEETS_REPORTS}
                        element={<TimesReportsPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_BAMBOO_TIME_OFF_REPORT}
                        element={<BambooTimeOffReportPage />}
                      />
                    )}

                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_CHANGE_TO_HOLIDAYS_REPORT}
                        element={<ChangesToHolidaysReportPage />}
                      />
                    )}

                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_QUERY_TIMESHEETS}
                        element={<TimesQueryPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_QUERY_RESULTS_TIMESHEETS}
                        element={<QueryResultsPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_APPROVE_TIME_TIMESHEETS}
                        element={<ApproveTimePage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_APPROVE_TIME_TIMESHEETS_BY_USER}
                        element={<ApproveTimeByUserPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_TIME_CONFIRMATION_REPORT}
                        element={<TimesConfirmationReportsPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_TIME_CONFIRMATION_RESULTS}
                        element={<TimesConfirmationResultsPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_WHO_IS_OUT_REPORT}
                        element={<WhoIsOutReportsPage />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_MEAL_BREAK_REPORT}
                        element={<MealBreakReportsPage />}
                      />
                    )}
                    {/* Admin module routes */}
                    <Route
                      path={ROUTE_THEHUB_ADMIN}
                      element={
                        <AccessControl
                          allowedRoles={ADMIN_ACCESS}
                          fallback={<ForbiddenPage />}
                        >
                          <AdminPage />
                        </AccessControl>
                      }
                    />
                    {/* Not found page */}
                    <Route
                      path={ROUTE_NOT_FOUND_PAGE}
                      element={<NotFoundPage />}
                    />
                    {/* Forbidden page */}
                    <Route
                      path={ROUTE_FORBIDDEN_PAGE}
                      element={<ForbiddenPage />}
                    />
                    {/* Delete product page */}
                    <Route
                      path={ROUTE_DELETE_PRODUCT_PAGE}
                      element={<DeleteProductPage />}
                    />
                    {/* Access control report pages */}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_ACCESS_CONTROL_REPORT_BY_USER}
                        element={<AccessControlReportByUser />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_ACCESS_CONTROL_REPORT_BY_JIRA_PROJECT}
                        element={<AccessControlReportPageByJiraProject />}
                      />
                    )}
                    {auth.isAuthenticated && (
                      <Route
                        path={ROUTE_ACCESS_CONTROL_REPORT_BY_GITLAB_PROJECT}
                        element={<AccessControlReportPageByGitlabProject />}
                      />
                    )}

                    {/* Data Report report page */}
                    {auth.isAuthenticated &&
                      (activeUserEmail === "aestrada@itx.com" ||
                        activeUserEmail === "nmendez@itx.com") && (
                        <Route
                          path={ROUTE_SAMPLE_REPORT}
                          element={<SampleReportPage />}
                        />
                      )}
                    {/* Log out page */}
                    <Route path={ROUTE_LOG_OUT} element={<LogOutPage />} />
                    {auth.isAuthenticated && (
                      <Route path="*" element={<NotFoundPage />} />
                    )}
                    {!auth.isAuthenticated && (
                      <Route path="*" element={<LogOutPage />} />
                    )}
                  </Routes>
                )}
              </div>
            </div>
          </div>
        </Auth>
      </DataWrapper>
    </MsalAuthenticationTemplate>
  );
}

export default App;
