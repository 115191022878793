import { Role } from "../../types";
import {
  BILLING_MODULE,
  ROLE_BILLING_MODULE,
  ROLE_RECURRING_BILLING_ADMIN,
  ROLE_SUPER_ADMIN,
} from "./constants";

// TODO: WILL REPLACE FUNCTION BELOW
/* check access for The Module
- first param: ask is need to check roles access
- second param: roles needed to access
- third param: roles that user has in module
*/
export const verifyModuleAccess = (
  requireModuleAccess: boolean | undefined,
  rolesModuleAccess: string[] | undefined,
  rolesModuleList: string[]
) => {
  let response: any = false;
  if (!requireModuleAccess) {
    response = true;
  } else if (requireModuleAccess) {
    response = rolesModuleAccess?.some((elem) =>
      rolesModuleList.includes(elem)
    );
  }
  return response;
};

// Param is array of roles only for time module
export const hasSuperAdminAccess = (userRoles: any) => {
  let response: boolean = false;
  userRoles?.forEach((role) => {
    if (role.name === ROLE_SUPER_ADMIN) {
      response = true;
    }
  });
  return response;
};

// ASUITE11-2647: need to
export const isOnlyRecurringBillingAdminRole = (userRoles: any) => {
  let response: boolean = false;
  let multipleBillingRole: any = [];
  userRoles?.forEach((role) => {
    if (role.module.name.toLowerCase() === BILLING_MODULE) {
      multipleBillingRole.push(role);
    }
  });
  if (
    multipleBillingRole.length === 1 &&
    multipleBillingRole[0].name === ROLE_RECURRING_BILLING_ADMIN
  ) {
    response = true;
  }
  return response;
};

// Helper to know if have a billing role
export const hasBillingRole = (userRoles: Role[] | null | undefined) => {
  let result =
    userRoles?.some((role) =>
      Array.isArray(ROLE_BILLING_MODULE)
        ? ROLE_BILLING_MODULE.find(
            (allowedBillingRoles) => allowedBillingRoles === role.name
          )
        : ROLE_BILLING_MODULE === role.name
    ) || false;
  return result;
};
